import { useEffect } from "react";
import LoginContent from "../Components/LoginContent"

const Login = () => {
    useEffect(() => {
        document.title = "Login"
    }, []);

    return (
        <LoginContent />

    );
}

export default Login;