import { Fragment, SetStateAction, useEffect, useState } from 'react';
import AddBreadcrumb from '../../Components/Layout/Breadcrumb/AddBreadcrumb';
import DefaultLayout from '../Layout/DefaultLayout';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import AlertDialog from '../../Components/AlertDialog';
import User from '../../Model/User';
import { useCurrentUser } from '../../Providers/UserProvider';
import showErrorToast from '../../Util/Widgets/Toasts/ShowErrorToast';
import showSuccessToast from '../../Util/Widgets/Toasts/ShowSuccessToast';
import { Menu, Transition } from '@headlessui/react';
import LoadingWidget from '../../Util/Widgets/LoadingWidget';

const DispatchTchPillowManagement = () => {
  useEffect(() => {
    document.title = "Order Dispatch Management"
  }, []);

  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useCurrentUser();
  const baseURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();

  useEffect(() => {
    getOrder(currentUser.id);
  }, [])


  const getOrder = async (id: any) => {
    try {
      let getAllUserURL = (baseURL + `/getAllPatient_PillowOrder`)

      const response = await axios.get(getAllUserURL)
      if (response.data.Success) {
        setOrders(response.data.Success);
      } else {
        showErrorToast(response.data.Error);
      }
    } catch (error) {
      console.error('Error: ', error);
      showErrorToast('Error: ' + error);
    } finally {
      setLoading(false);
    }
  }

  //Delete Order Dialog
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState();
  const [currentUserFullname, setCurrentUserFullname] = useState();
  const openDialog = (id: any, fullname: any) => { setDialogIsOpen(true); setCurrentOrderId(id); setCurrentUserFullname(fullname); }
  const closeDialog = () => setDialogIsOpen(false);

  const handleDeleteOnClick = (id: any, fullname: String) => {
    openDialog(id, fullname);
  }

  const handleDelete = async () => {
    try {
      const response = await axios.delete(baseURL + `/deletePatient_PillowOrder/` + currentOrderId);
      if (response.data.Success) {
        window.location.reload();
        showSuccessToast(response.data.Success);
      } else {
        showErrorToast(response.data.Error);
      }
    } catch (error) {
      console.error('Error: ', error);
      showErrorToast('Error: ' + error);
    }
  }

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const orderList = orders.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(orders.length / recordsPerPage);
  const pageNumbers = [...Array(nPage + 1).keys()].slice(1);


  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <div>
      <div>
        <div className="lg:flex lg:items-center lg:justify-between mb-5">
          <div className="min-w-0 flex-1">
            <div className="flex justify-start mb-5">
              <NavLink end to={'..'} onClick={(e) => {
                e.preventDefault();
                navigate('/tchPillowPatientManagement');
              }} className="flex items-center">
                <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-500 dark:fill-gray-400" aria-hidden="true" />
                <p className="text-sm text-gray-500 dark:text-gray-400 sm:truncate sm:tracking-tight">Back</p>
              </NavLink>


            </div>
            <h2 className="text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:tracking-tight">
              Order Dispatch Management
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                Efficiently Administer Your TCH Platform: User Management Hub
              </div>

            </div>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0">

            <NavLink end to='/addDispatchTchPillow'>
              <span className="">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create New Order Dispatch
                </button>
              </span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-[#182235] px-4 py-3 sm:px-6 rounded-md overflow-x-auto">

        <ul className="divide-y divide-gray-100 dark:divide-gray-600">
          {(loading) ?
            <div className='py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center'>
              <LoadingWidget />
              <p className="mt-4 text-sm font-medium leading-6 text-gray-500 dark:text-white text-center">{`Loading Order Data...`}</p>
            </div>
            :
            (orderList.length > 0) ? orderList.map((order: any, i: any) => (
              <li key={order.id} className="flex gap-x-6 py-5 justify-between">
                <div className="flex min-w-0 gap-x-4">
                  {/* <div className="hidden sm:flex inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-indigo-100 rounded-full dark:bg-gray-600">
                    <span className="font-medium text-gray-600 dark:text-gray-300">{order.fullname[0]}</span>
                  </div> */}
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">{order.fullname}</p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-gray-400">{order.method}</p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:items-center grid grid-cols-5 gap-6 mr-6">
                  <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">{order.status}</span>
                  <NavLink end to={`/editDispatchTchPillow/${order.id}`}><PencilSquareIcon className="h-4 w-4" /></NavLink>
                  <TrashIcon className="h-4 w-4" aria-hidden="true" onClick={() => handleDeleteOnClick(order.id, order.fullname)} />
                </div>
                <Menu as="div" className="relative ml-3 sm:hidden">
                  <Menu.Button className="inline-flex items-center rounded-md bg-white px-4 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                    More
                    <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink end to={`/editDispatchTchPillow/${order.id}`}>
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Edit
                            </a></NavLink>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#" onClick={() => handleDeleteOnClick(user.id, user.fullname)}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Delete
                          </a>
                        )}
                      </Menu.Item> */}
                      {/* <Menu.Item>
                        {({ active }) => (
                          <NavLink end to={`/viewUser/${user.id}`}>
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              View
                            </a></NavLink>
                        )}
                      </Menu.Item> */}
                    </Menu.Items>
                  </Transition>
                </Menu>

              </li>
            )) : <p className="py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center">{`No user found.`}</p>}
        </ul>
        <AlertDialog open={dialogIsOpen} onClose={closeDialog} title={"Delete User"} message={"Confirm delete " + currentUserFullname + "'s order tracking from database?"} handleDeleteFunction={handleDelete} />

        <div>
          <div className="flex flex-1 justify-between sm:hidden mt-4">
            <a
              onClick={prePage}
              className="relative inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-gray-300"
            >
              Previous
            </a>
            <a
              onClick={nextPage}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-gray-300"
            >
              Next
            </a>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between mt-8">
            <div>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Showing <span className="font-medium">{firstIndex + 1}</span> to <span className="font-medium">{lastIndex}</span> of{' '}
                <span className="font-medium">{orderList.length}</span> results
              </p>
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <a
                  onClick={prePage}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                {
                  pageNumbers.map((n, i) => (
                    <a

                      aria-current="page"
                      key={i}
                      className={`${currentPage === n ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:z-20 focus:outline-offset-0'}`}
                      onClick={() => changeCurrentPage(n)}
                    >
                      {n}
                    </a>
                  ))
                }

                <a
                  onClick={nextPage}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>

    </div>

  );

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCurrentPage(id: SetStateAction<number>) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  }
}

export default DispatchTchPillowManagement;