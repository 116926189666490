import showErrorToast from './Widgets/Toasts/ShowErrorToast';

const ValidateUserForm = function (values) {
    //Validate Form
    let message = "";
    let proceed = false;
    try {
        if (values.username.length === 0) {
            message = message + "Username cannot be empty."
        }
        else if (values.username.length > 0 && values.username.length < 8) {
            message = message + "Username must be more than 8 characters long."
        }
        else if (values.password.length === 0) {
            message = message + "Password cannot be empty."
        }
        else if (values.password.length > 0 && values.password.length < 8) {
            message = message + "Password must be more than 8 characters long."
        }
        else if (values.confirmPassword !== values.password) {
            message = message + "Password not match."
        }
        else if (values.role_id !== "1" && values.agent_no.length === 0) {
            message = message + "Authorized agent number cannot be empty."
        }
        else if (values.fullname.length === 0) {
            message = message + "Fullname cannot be empty."
        }
        else if (values.email.length === 0) {
            message = message + "Email address cannot be empty."
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            message = message + "Email address is invalid"
        }
        else if (values.mobile_no.length === 0) {
            message = message + "Mobile No cannot be empty."
        }
        else {
            proceed = true;
        }
        if (!proceed) {
            showErrorToast(message);
        } else {
            return proceed;
        }
    } catch (error) {
        console.error('Error:', error);
        showErrorToast('Error:' + error);
    }
}


export default ValidateUserForm;