// src/components/Notification.js
import { BellAlertIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';

const NotificationWidget = ({ message, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(onClose, 300); // Match the duration of the transition
    };

    return (
        <div
            className={`fixed top-4 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 rounded-md shadow-lg p-4 max-w-md z-50 transition-all duration-300 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'
                }`}
        >
            <div className="flex items-center">
                <div className="mr-4">
                    <BellAlertIcon className="w-6 h-6 text-slate-800" />
                </div>
                <div className="flex-1">
                    <p className="text-sm font-medium">New Notification</p>
                    <p className="text-xs text-gray-600">{message}</p>
                </div>
                <button className="ml-3 text-gray-500 hover:text-gray-700" onClick={handleClose}>
                    <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default NotificationWidget;
