import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { all } from "axios";
import showErrorToast from "../Util/Widgets/Toasts/ShowErrorToast";
import { useCurrentUser } from "./UserProvider";
import showLoadingToast from "../Util/Widgets/Toasts/ShowLoadingToast";
import toast from "react-hot-toast";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("tch-my_login_token") || "");
    const navigate = useNavigate();
    axios.defaults.withCredentials = true;
    const { setCurrentUser } = useCurrentUser();
    const baseURL = process.env.REACT_APP_BASEURL;

    const loginAction = async (username, password) => {

        try {
            const response = await axios.post(baseURL + '/login', { username, password });
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            const user = response.data.user;
            const token = response.data.token;
            localStorage.setItem('tch-my_login_token', token);
            setUser(user);
            setCurrentUser(user);
            setToken(token);
            navigate("/home");
        } catch (error) {
            console.error('Login Error:', error);
            showErrorToast('Login Error: ' + error);
        }
    };

    const logOut = async () => {

        showLoadingToast('Logging out...');
        try {
            const response = await axios.get(baseURL + '/logout');
            if (response.data.Success) {
                // already clearing
                setTimeout(() => {
                    setUser(null);
                    setToken("");
                    localStorage.clear()
                    localStorage.removeItem("tch-my_currentUser")
                    localStorage.removeItem("tch-my_login_token");
                    navigate("/login");
                }, 2000);

            } else {
                showErrorToast("Logout Failed. Please try again.")
            }
        } catch (error) {
            console.error('Error logging out:', error);
            showErrorToast('Logging out error: ' + error);
        }
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};