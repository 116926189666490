import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import showErrorToast from "../Util/Widgets/Toasts/ShowErrorToast";
import axios from "axios";
import showSuccessToast from "../Util/Widgets/Toasts/ShowSuccessToast";
import emailjs from '@emailjs/browser';
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import AlertDialog from "../Components/AlertDialog";
import RecaptchaDialog from "../Components/RecaptchaDialog";
import showLoadingToast from "../Util/Widgets/Toasts/ShowLoadingToast";

const ForgotPassword = () => {

    const [username, setUsername] = useState([]);
    const [foundUser, setFoundUser] = useState([]);
    const navigate = useNavigate();

    const baseURL = process.env.REACT_APP_BASEURL;
    const emailJSPrivateKey = process.env.REACT_APP_EMAILJS_PRIVATEKEY;
    const emailJSServiceID = process.env.REACT_APP_EMAILJS_SERVICEID_GMAIL;
    const emailJSTemplateID = process.env.REACT_APP_EMAILJS_TEMPLATEID_RESETPASS;

    useEffect(() => {
        document.title = "Forgot Password";
        emailjs.init(emailJSPrivateKey || "");
    }, []);

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        handleCheckUsername(e);
    }

    const handleCheckUsername = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            showLoadingToast("Resetting Password...")
            const response = await axios.post(baseURL + '/getUserByUsername', { username });
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setFoundUser(response.data.Success[0]);
                handleResetPassword(e, response.data.Success[0].id, response.data.Success[0].fullname, response.data.Success[0].email);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error: ' + error);
        }
    }

    const handleResetPassword = async (e: { preventDefault: () => void; }, id: any, userFullname: any, userEmail: any) => {
        e.preventDefault();

        try {
            const newPassword = generateRandomString(12);
            const response = await axios.put(baseURL + '/resetPassword/' + id, { newPassword });
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                await emailjs.send(emailJSServiceID || '', emailJSTemplateID || '', {
                    fullname: userFullname,
                    tempPassword: newPassword,
                    user_email: userEmail,
                });
                //showSuccessToast(response.data.Success);
                showSuccessToast('A new password will be sent to email.');
                closeDialog();
            }

        } catch (error) {
            console.error(error);
            showErrorToast(error);
        }
    }

    // Function to generate a random string of a specified length
    const generateRandomString = (length: any) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
        return randomString;
    };

    //Recaptcha
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const openDialog = () => { setDialogIsOpen(true); }
    const closeDialog = () => setDialogIsOpen(false);

    const handleRecaptchaOnClick = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        openDialog();
    }

    return (
        <div>
            <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    {/* <h3 className="bg-gradient-to-r from-orange-400 via-indigo-400 to-indigo-600 text-transparent bg-clip-text text-center font-bold text-xl">TCH Healthcare Malaysia</h3 > */}

                    <h2 className=" text-left text-lg font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                        Forgot Password?
                    </h2>
                </div>

                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6">
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Username
                            </label>
                            <div className="mt-2">
                                <input
                                    id="text"
                                    name="username"
                                    type="username"
                                    autoComplete="username"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                    onChange={(e: any) => setUsername(e.target.value)}
                                />
                            </div>
                        </div>

                        <div>

                            <button
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleRecaptchaOnClick}
                            >
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    {/* <NavLink end to={'/login'} ><h4 className="mt-28 text-center text-sm font-medium leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                        Back to Login
                    </h4></NavLink> */}
                    <NavLink end to={'..'} onClick={(e) => {
                        e.preventDefault();
                        navigate('/login');
                    }} className="flex items-center justify-center mt-28">
                        <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-900 dark:fill-gray-400" aria-hidden="true" />
                        <p className="text-sm text-gray-900 dark:text-gray-400 sm:truncate sm:tracking-tight text-center">Back to Login Page</p>
                    </NavLink>
                </div>
                <RecaptchaDialog open={dialogIsOpen} onClose={closeDialog} title={"Are you a human?"} message={"Google Recaptcha Verification"} handleDeleteFunction={handleSubmit} />

            </div>
        </div>
    );
}

export default ForgotPassword;