import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const useCurrentUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem('tch-my_currentUser');
    if (storedUser !== "undefined") {
      return JSON.parse(storedUser);
    } else {
      return null; // or any default value you want to use when the user is not found
    }
  });

  // Update local storage when currentUser changes
  useEffect(() => {
    localStorage.setItem('tch-my_currentUser', JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};