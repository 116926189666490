import showErrorToast from './Widgets/Toasts/ShowErrorToast';

const ValidatePillowPatientForm = function (values) {
    //Validate Form
    let message = "";
    let proceed = false;
    try {
        if (values.fullname.length === 0) {
            message = message + "Fullname cannot be empty."
        }
        else if (values.dob.length === 0) {
            message = message + "Date of Birth cannot be empty."
        }
        else if (values.gender.length === 0) {
            message = message + "Gender cannot be empty."
        }
        else if (values.sagittal_suture.length === 0) {
            message = message + "Sagittal Suture cannot be empty."
        }
        else if (values.external_occipital_protuberance.length === 0) {
            message = message + "External Occipital Protuberance cannot be empty."
        }
        else if (values.both_shoulders_align.length === 0) {
            message = message + "Both Shoulders Align cannot be empty."
        }
        else if (values.purchasequantity.length === 0) {
            message = message + "Purchase Quantity cannot be empty."
        }
        else {
            proceed = true;
        }
        if (!proceed) {
            showErrorToast(message);
        } else {
            return proceed;
        }
    } catch (error) {
        console.error('Error:', error);
        showErrorToast('Error:' + error);
    }
}


export default ValidatePillowPatientForm;