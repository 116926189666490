import React, { useState, ReactNode, useEffect } from 'react';
import Topbar from '../../Components/Layout/Topbar';
import Sidebar from '../../Components/Layout/Sidebar';
import { io } from 'socket.io-client';
import NotificationWidget from '../../Util/Widgets/NotificationWidget';
import { useCurrentUser } from '../../Providers/UserProvider';
import User from '../../Model/User';

const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState('');

  const { currentUser } = useCurrentUser();

  const socketURL = process.env.REACT_APP_SOCKETURL;

  const triggerNotification = (message: any) => {
    setMessage(message);
    setShowNotification(true);

    // Hide the notification after 3 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  const debounce = (func: any, delay: any) => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    const socketURL = process.env.REACT_APP_SOCKETURL;

    const socket = io(socketURL || "");  // Adjust the URL based on your backend server

    // Listen for real-time notifications
    const handleNewNotification = (data: any) => {
      // Set flag for new notification
      triggerNotification(data.message);
    };

    // Debounce the handleNewNotification function with a delay of 1000ms
    const debouncedHandleNewNotification = debounce(handleNewNotification, 1000);

    socket.on('newNotification', debouncedHandleNewNotification);

    return () => {
      // Clean up socket event listener
      socket.off('newNotification', handleNewNotification);
      socket.disconnect();
    };
  }, []);

  return (
    <div className="bg-slate-100 dark:bg-slate-900 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Topbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            {/* {(showNotification) ?
              <NotificationWidget message={message} onClose={() => setShowNotification(false)} />
              : <div></div>} */}
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10 bg-slate-100 dark:bg-slate-900">
              {children}
            </div>
            {(showNotification && currentUser.is_admin == "1") && (
              <NotificationWidget message={message} onClose={() => setShowNotification(false)} />
            )}
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};

export default DefaultLayout;