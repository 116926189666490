import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon, CheckIcon, PhotoIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useCurrentUser } from '../../Providers/UserProvider';
import User from '../../Model/User';
import axios from 'axios';
import Patient_Pillow from '../../Model/Patient_Pillow';
import showErrorToast from '../../Util/Widgets/Toasts/ShowErrorToast';
import showLoadingToast from '../../Util/Widgets/Toasts/ShowLoadingToast';
import showSuccessToast from '../../Util/Widgets/Toasts/ShowSuccessToast';
import dateFormat from 'dateformat';
import Patient_PillowOrder from '../../Model/Patient_PillowOrder';


const EditDispatchTchPillow = () => {

    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;
    const { currentUser } = useCurrentUser();
    const [order, setOrder] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getOrder(id);
    }, [])

    const getOrder = async (id: any) => {
        try {
            const response = await axios.get(baseURL + `/getPatient_PillowOrder/${id}`);
            if (response.data.Success) {
                setOrder(response.data.Success);
                setValues({ ...values, patient_fullname: response.data.Success[0].fullname, patient_id: response.data.Success[0].patient_id, method: response.data.Success[0].method, tracking_no: response.data.Success[0].tracking_no, status: response.data.Success[0].status, remarks: response.data.Success[0].remarks });
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    useEffect(() => {
        document.title = "Order Dispatch"
    }, []);

    const methods = [
        {
            name: "Self Door-to-door Delivery",
            value: "Self Door-to-door Delivery",
        },
        {
            name: "Delivery Service",
            value: "Delivery Service",
        },
        {
            name: "Self Pick-up",
            value: "Self Pick-up",
        }
    ]

    const statuses = [

        {
            name: "Shipped",
            value: "SHIPPED",
        },
        {
            name: "Delivered",
            value: "DELIVERED",
        },

    ]

    const [values, setValues] = useState({
        patient_fullname: (order as unknown as Patient_PillowOrder).patient_fullname,
        patient_id: (order as unknown as Patient_PillowOrder).patient_id,
        method: (order as unknown as Patient_PillowOrder).method,
        tracking_no: (order as unknown as Patient_PillowOrder).tracking_no,
        status: (order as unknown as Patient_PillowOrder).status,
        remarks: (order as unknown as Patient_PillowOrder).remarks,
    })

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        setValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleUpdate = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            showLoadingToast("Updating Patient Order...")
            const response = await axios.put(baseURL + '/updatePatient_Pillow_Order/' + id, values);
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setTimeout(() => {
                    showSuccessToast(response.data.Success);
                    navigate('/dispatchTchPillowManagement');
                }, 2000)
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    return (
        <div>
            {(currentUser as unknown as User).is_admin ? <div>

                <div>
                    <div className="flex justify-start mb-5">
                        <NavLink end to={'..'} onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }} className="flex items-center">
                            <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-500 dark:fill-gray-400" aria-hidden="true" />
                            <p className="text-sm text-gray-500 dark:text-gray-400 sm:truncate sm:tracking-tight">Back</p>
                        </NavLink>


                    </div>
                    <div className="lg:flex lg:items-center lg:justify-between mb-5">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:tracking-tight">
                                Order Dispatch
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">


                            <span className="ml-0">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={handleUpdate}
                                >
                                    <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                    Save
                                </button>
                            </span>
                        </div>
                    </div>
                </div>

                <div className='bg-white dark:bg-[#182235] px-3 py-5 sm:px-6 rounded-md'>
                    <form onSubmit={handleUpdate}>
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Dispatch Pillow Order</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                    Please select patient and update their order status.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="patient_fullname" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                            Patient
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    disabled
                                                    type="text"
                                                    name="patient_fullname"
                                                    id="patient_fullname"
                                                    autoComplete="patient_fullname"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 items-center disabled:rounded disabled:bg-gray-200"
                                                    onChange={handleChange}
                                                    value={(values.patient_fullname) || "-"}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="method" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Delivery Methods
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="method"
                                                name="method"
                                                autoComplete="method"
                                                className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-sm sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                onChange={handleChange} value={(values.method)}
                                            >
                                                {methods.map((method, i) => (
                                                    <option key={i} className='px-2 py-2' value={method.value}>{method.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="tracking_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                            Tracking Number (Optional)
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    maxLength={50}
                                                    type="text"
                                                    name="tracking_no"
                                                    id="tracking_no"
                                                    autoComplete="tracking_no"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={(values.tracking_no || "")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Status
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="status"
                                                name="status"
                                                autoComplete="status"
                                                className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-sm sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                onChange={handleChange} value={(values.status)}
                                            >
                                                {statuses.map((status, i) => (
                                                    <option key={i} className='px-2 py-2' value={status.value}>{status.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                            Remarks (Optional)
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-2xl">
                                                <input
                                                    maxLength={50}
                                                    type="text"
                                                    name="remarks"
                                                    id="remarks"
                                                    autoComplete="remarks"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={(values.remarks || "")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                            <NavLink end to={'..'} onClick={(e) => {
                                e.preventDefault();
                                navigate('/dispatchTchPillowManagement');
                            }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                    Cancel
                                </button></NavLink>

                            <button
                                type="submit"
                                className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div >
            </div> : <div className='bg-white dark:bg-[#182235] px-4 py-5 sm:px-6 rounded-md h-96 content-center'><p className="text-sm leading-6 text-gray-600 dark:text-gray-400 text-center">
                You have no permission to view this page.
            </p></div>}
        </div>

    );
}

export default EditDispatchTchPillow;