import { useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import UpdateBreadcumb from "../../Components/Layout/Breadcrumb/UpdateBreadcrumb";
import { NavLink, useNavigate } from "react-router-dom";
import User from "../../Model/User";
import axios from "axios";
import showSuccessToast from "../../Util/Widgets/Toasts/ShowSuccessToast";
import showErrorToast from "../../Util/Widgets/Toasts/ShowErrorToast";
import { useCurrentUser } from "../../Providers/UserProvider";
import showLoadingToast from "../../Util/Widgets/Toasts/ShowLoadingToast";

const EditProfile = () => {

    const { currentUser, setCurrentUser } = useCurrentUser();
    const baseURL = process.env.REACT_APP_BASEURL;

    const [values, setValues] = useState({
        id: (currentUser as unknown as User).id,
        username: (currentUser as unknown as User).username,
        fullname: (currentUser as unknown as User).fullname,
        role_id: (currentUser as unknown as User).role_id,
        rolename: (currentUser as unknown as User).rolename,
        email: (currentUser as unknown as User).email,
        mobile_no: (currentUser as unknown as User).mobile_no,
        country: (currentUser as unknown as User).country,
        street_address: (currentUser as unknown as User).street_address,
        related_agent: (currentUser as unknown as User).related_agent,
        agent_fullname: (currentUser as unknown as User).agent_fullname,
        created_time: (currentUser as unknown as User).created_time,
    })

    const handleUpdate = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            showLoadingToast("Updating Profile Data...")
            const response = await axios.put(baseURL + '/updateUser/' + (currentUser as unknown as User).id, values)
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setTimeout(() => {
                    showSuccessToast(response.data.Success);
                    getUpdatedData((currentUser as unknown as User).id)

                }, 2000)
            }
        } catch (error) {
            showErrorToast(error);
        }
    }

    const getUpdatedData = async (id: any) => {
        try {
            const response = await axios.get(baseURL + '/getUserById/' + id)
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setCurrentUser(response.data.Success[0]);
                navigate('/viewProfile');
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }


    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const navigate = useNavigate();

    return (
        <div>
            <UpdateBreadcumb pageName="Update User" updateFunction={handleUpdate} />
            <div className='bg-white dark:bg-[#182235] px-3 py-5 sm:px-6 rounded-md'>
                <form onSubmit={handleUpdate}>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">User Account</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                Please provide necessary information to create user account.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Username
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                disabled
                                                type="text"
                                                name="username"
                                                id="username"
                                                autoComplete="username"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 items-center disabled:bg-gray-200 disabled:rounded"
                                                onChange={handleChange}
                                                value={values.username || "-"}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Personal Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">Use a permanent address where you can receive mail.</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Fullname (As Identity Card)
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                maxLength={50}
                                                type="text"
                                                name="fullname"
                                                id="fullname"
                                                autoComplete="fullname"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                onChange={handleChange}
                                                value={values.fullname}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="mobile_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Mobile Phone Number
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={15}
                                            id="mobile_no"
                                            name="mobile_no"
                                            type="mobile_no"
                                            autoComplete="mobile_no"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                            value={values.mobile_no}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Country
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country_name"
                                            className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                            value={values.country}
                                        >
                                            <option className='px-2 py-2' value={"Malaysia"}>Malaysia</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street_address" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Street address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={90}
                                            type="text"
                                            name="street_address"
                                            id="street_address"
                                            autoComplete="street_address"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                            value={values.street_address}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                        <NavLink end to={'..'} onClick={(e) => {
                            e.preventDefault();
                            navigate('/viewProfile');
                        }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                Cancel
                            </button></NavLink>

                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>

        </div>
    )

}

export default EditProfile;