import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './Components/Utils/ThemeContext';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './Providers/UserProvider';
import { Toaster } from 'react-hot-toast';
import AuthProvider from './Providers/AuthProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Toaster
      position="bottom-right"
    />
    <ThemeProvider>
      <BrowserRouter>
        <UserProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </UserProvider>
      </BrowserRouter>
    </ThemeProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
