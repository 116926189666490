import toast, { ErrorIcon } from 'react-hot-toast';

const showErrorToast = function (message) {
    toast.custom((t) => (

        <div id="toast-danger" className="flex items-center w-full max-w-xs p-3 text-gray-500 bg-white rounded border-gray-200 dark:border-gray-700 border border-1 shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-green-200">
                <ErrorIcon></ErrorIcon>
            </div>
            <div className="ms-3 text-sm font-normal">{message}</div>
            <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" onClick={() => toast.dismiss(t.id)}>
                <span className="sr-only">Close</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
            </button>
        </div>
    ), { duration: 1300 })
}

export default showErrorToast;