import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useAuth } from "../Providers/AuthProvider";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import showErrorToast from "../Util/Widgets/Toasts/ShowErrorToast";
import { useCurrentUser } from "../Providers/UserProvider";
import { useNavigate } from "react-router-dom";

export default function LoginContent() {

    const [input, setInput] = useState({
        username: "",
        password: "",
    });

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const baseURL = process.env.REACT_APP_BASEURL;

    const handleSubmitEvent = async (e: any) => {
        e.preventDefault();
        const loginUsername = input.username;
        const loginPassword = input.password;

        if (loginUsername !== "" && loginPassword !== "") {
            setLoading(true);
            // Add a delay of 1 second before navigating to "/home"
            setTimeout(() => {
                setLoading(false);
                auth.loginAction(loginUsername, loginPassword);
            }, 1000);
        } else {
            showErrorToast('Please enter a valid value.')
        }
    };

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        // Remove leading and trailing spaces
        const trimmedValue = value.trim();

        // Convert value to lowercase and remove spaces
        const sanitizedValue = trimmedValue.toLowerCase().replace(/\s/g, '');

        // Remove special characters using regex
        const sanitizedValueWithoutSpecialChars = sanitizedValue.replace(/[^\w]/g, '');

        // Update the state with the sanitized value
        setInput(prevValues => ({
            ...prevValues,
            username: sanitizedValueWithoutSpecialChars
        }));
    };

    return (
        <>
            <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h3 className="bg-gradient-to-r from-orange-500 via-indigo-400 to-pink-900 text-transparent bg-clip-text text-center font-bold text-3xl">TCH MALAYSIA</h3 >
                    <p className="mt-2 text-sm leading-6 text-gray-400 dark:text-gray-400 text-center text-bold">
                        Connecting You to Better Health
                    </p>

                    <h2 className="mt-28 text-left text-lg font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                        Sign in to your account.
                    </h2>
                </div>

                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmitEvent}>
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Username
                            </label>
                            <div className="mt-2">
                                <input
                                    id="text"
                                    name="username"
                                    type="username"
                                    autoComplete="username"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                    onChange={handleUsernameChange} value={input.username}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                    Password
                                </label>
                                <div className="text-sm">
                                    <a href="/forgotPassword" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                        Forgot password?
                                    </a>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div className="relative">
                                    <input
                                        id="password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                                        onChange={handleInput}
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-500 cursor-pointer focus:outline-none"
                                    >
                                        {showPassword ? (
                                            <EyeSlashIcon className="h-4 w-4 text-grey-300" />
                                        ) : (
                                            <EyeIcon className="h-4 w-4 text-grey-500" />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* <div className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                <MoonLoader color="#FFFFFF" size={14} />
                            </div> */}
                            {(!loading) ? <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Login
                            </button>

                                : <button type="button" className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" disabled>
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 mr-2 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                    </div>
                                    Logging in...
                                </button>}
                        </div>
                    </form>
                </div>
                <p className="mt-1 text-[10px] leading-6 text-gray-300 dark:text-gray-300 text-center text-bold">
                    TCH Server Base URL: {baseURL}
                </p>
                <p className="mt-28 text-[12px] leading-6 text-gray-500 dark:text-gray-500 text-center text-bold">
                    ©TCH Malaysia 2024 by Kingdom Code Creations (EW). All rights reserved.
                </p>

                {/* <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <NavLink end to={'/signUp'} ><h4 className="mt-28 text-center text-sm font-medium leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                        Don't have an account?
                    </h4></NavLink>
                </div> */}
            </div>
        </>
    )
}

