import './Styles/style.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import UserManagement from './Pages/UserManagement/UserManagement';
import AddUser from './Pages/UserManagement/AddUser';
import ViewUser from './Pages/UserManagement/ViewUser';
import TchPillowPatientManagement from './Pages/TchPillowPatientManagement/TchPillowPatientManagement';
import AddTchPillowPatient from './Pages/TchPillowPatientManagement/AddTchPillowPatient';
import ViewTchPillowPatient from './Pages/TchPillowPatientManagement/ViewTchPillowPatient';
import Login from './Pages/Login';
import EditUser from './Pages/UserManagement/EditUser';
import EditTchPillowPatient from './Pages/TchPillowPatientManagement/EditTchPillowPatient';
import SignUp from './Pages/SignUp';
import PrivateRoute from './PrivateRoute';
import AuthProvider from './Providers/AuthProvider';
import AddTchPillowPatientImages from './Pages/TchPillowPatientManagement/AddTchPillowPatientImages';
import ViewProfile from './Pages/Profile/ViewProfile';
import EditProfile from './Pages/Profile/EditProfile';
import ChangePassword from './Pages/Profile/ChangePassword';
import ForgotPassword from './Pages/ForgotPassword';
import NotFound from './Pages/NotFound';
import SessionTimeout from './Pages/Container/SessionTimeoutContainer';
import AddUserCopy from './Pages/UserManagement/AddUserCopy';
import AddDispatchTchPillow from './Pages/TchPillowOrderDispatch/AddDispatchTchPillow';
import DispatchTchPillowManagement from './Pages/TchPillowOrderDispatch/DispatchTchPillowManagement';
import EditDispatchTchPillow from './Pages/TchPillowOrderDispatch/EditDispatchTchPillow';
import UserHierarchyTree from './Pages/UserManagement/UserHeirarchyTree';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace={true} />} />
        {/* <Route path="/signUp" element={<SignUp />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/addUserCopy" element={<AddUserCopy />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route element={<PrivateRoute />}>
          <Route element={<SessionTimeout />}>
            <Route path="/home" element={<Dashboard />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/viewProfile" element={<ViewProfile />} />
            <Route path="/editProfile" element={<EditProfile />} />

            <Route path="/userprofile/changepassword" element={<ChangePassword />} />
            <Route path="/userprofile/viewprofile" element={<ViewProfile />} />
            <Route path="/userprofile/editprofile" element={<EditProfile />} />

            <Route path="/usermanagement" element={<UserManagement />} />
            <Route path="/addUser" element={<AddUser />} />
            <Route path="/viewUser/:id" element={<ViewUser />} />
            <Route path="/editUser/:id" element={<EditUser />} />

            <Route path="/user/manageuser" element={<UserManagement />} />
            <Route path="/user/adduser" element={<AddUser />} />
            <Route path="/user/viewuser/:id" element={<ViewUser />} />
            <Route path="/user/edituser/:id" element={<EditUser />} />
            <Route path="/user/hierarchytree" element={<UserHierarchyTree />} />

            <Route path="/tchPillowPatientManagement" element={<TchPillowPatientManagement />} />
            <Route path="/addTchPillowPatient" element={<AddTchPillowPatient />} />
            <Route path="/addTchPillowPatientImages" element={<AddTchPillowPatientImages />} />
            <Route path="/viewTchPillowPatient/:id" element={<ViewTchPillowPatient />} />
            <Route path="/editTchPillowPatient/:id" element={<EditTchPillowPatient />} />
            <Route path="/addDispatchTchPillow" element={<AddDispatchTchPillow />} />
            <Route path="/editDispatchTchPillow/:id" element={<EditDispatchTchPillow />} />
            <Route path="/dispatchTchPillowManagement" element={<DispatchTchPillowManagement />} />

            <Route path="/tchpillow/managepatient" element={<TchPillowPatientManagement />} />
            <Route path="/tchpillow/addpatient" element={<AddTchPillowPatient />} />
            <Route path="/tchpillow/viewpatient/:id" element={<ViewTchPillowPatient />} />
            <Route path="/tchpillow/editpatient/:id" element={<EditTchPillowPatient />} />
            <Route path="/tchpillow/addpatientimages" element={<AddTchPillowPatientImages />} />
            <Route path="/tchpillow/order/adddispatch" element={<AddDispatchTchPillow />} />
            <Route path="/tchpillow/order/editdispatch/:id" element={<EditDispatchTchPillow />} />
            <Route path="/tchpillow/order/managedispatch" element={<DispatchTchPillowManagement />} />

          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
