import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./Providers/AuthProvider";
import { useCurrentUser } from "./Providers/UserProvider";
import DefaultLayout from "./Pages/Layout/DefaultLayout";

const PrivateRoute = () => {
  const auth = useAuth();
  const { currentUser } = useCurrentUser();
  if (!auth.token && !currentUser) return <Navigate to="/login" />;
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

export default PrivateRoute;