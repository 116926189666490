import { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import UpdateBreadcumb from "../../Components/Layout/Breadcrumb/UpdateBreadcrumb";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Patient_Pillow from "../../Model/Patient_Pillow";
import dateFormat from "dateformat";
import PatientMeasureInfo from '../../images/Pillow_AddPatientInfo.png'
import axios from "axios";
import showErrorToast from "../../Util/Widgets/Toasts/ShowErrorToast";
import showSuccessToast from "../../Util/Widgets/Toasts/ShowSuccessToast";
import ValidatePillowPatientUpdateForm from "../../Util/ValidatePillowPatientUpdateForm";
import showLoadingToast from "../../Util/Widgets/Toasts/ShowLoadingToast";

const EditTchPillowPatient = () => {

    useEffect(() => {
        document.title = "Edit Patient"
    }, []);

    const { id } = useParams();
    const navigate = useNavigate();
    const [patient, setPatient] = useState<Patient_Pillow[]>([]);
    const baseURL = process.env.REACT_APP_BASEURL;
    useEffect(() => {
        getPatientData();
    }, [])

    const getPatientData = async () => {
        try {
            const response = await axios.get(baseURL + '/getPatient_Pillow/' + id)
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setPatient(response.data.Success[0]);
                setValues({ ...values, fullname: response.data.Success[0].fullname, dob: dateFormat(response.data.Success[0].dob, "yyyy-mm-dd"), email: response.data.Success[0].email, mobile_no: response.data.Success[0].mobile_no, height: response.data.Success[0].height, weight: response.data.Success[0].weight, gender: response.data.Success[0].gender, street_address: response.data.Success[0].street_address, sagittal_suture: response.data.Success[0].sagittal_suture, external_occipital_protuberance: response.data.Success[0].external_occipital_protuberance, both_shoulders_align: response.data.Success[0].both_shoulders_align, remarks: response.data.Success[0].remarks, purchasequantity: response.data.Success[0].purchasequantity, related_agent: response.data.Success[0].related_agent });

            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    const [values, setValues] = useState({
        fullname: (patient as unknown as Patient_Pillow).fullname,
        dob: (patient as unknown as Patient_Pillow).dob,
        email: (patient as unknown as Patient_Pillow).email || '',
        mobile_no: (patient as unknown as Patient_Pillow).mobile_no || '',
        height: (patient as unknown as Patient_Pillow).height,
        weight: (patient as unknown as Patient_Pillow).weight,
        gender: (patient as unknown as Patient_Pillow).gender,
        street_address: (patient as unknown as Patient_Pillow).street_address,
        sagittal_suture: (patient as unknown as Patient_Pillow).sagittal_suture,
        external_occipital_protuberance: (patient as unknown as Patient_Pillow).external_occipital_protuberance,
        both_shoulders_align: (patient as unknown as Patient_Pillow).both_shoulders_align,
        remarks: (patient as unknown as Patient_Pillow).remarks,
        purchasequantity: (patient as unknown as Patient_Pillow).purchasequantity,
        related_agent: (patient as unknown as Patient_Pillow).related_agent,

    })

    const handleUpdate = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (ValidatePillowPatientUpdateForm(values)) {
            try {
                showLoadingToast("Updating Patient...")
                const response = await axios.put(baseURL + '/updatePatient_Pillow/' + id, values);
                if (response.data.Error) {
                    showErrorToast(response.data.Error);
                }
                if (response.data.Success) {
                    setTimeout(() => {
                        showSuccessToast(response.data.Success);
                        navigate('/tchPillowPatientManagement');
                    }, 2000)
                }
            } catch (error) {
                console.error('Error:', error);
                showErrorToast('Error:' + error);
            }
        }
    }

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        let valueName = event.target.name;
        let value = event.target.value;
        setValues(oldValues => ({ ...oldValues, [valueName]: value }));
    }

    return (
        <div>
            <UpdateBreadcumb pageName="Update Patient" updateFunction={handleUpdate} />
            <div className='bg-white dark:bg-[#182235] px-4 py-5 sm:px-6 rounded-md'>
                <form onSubmit={handleUpdate}>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Patient Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                Please provide necessary information to create patient.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Fullname (As Identity Card) <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                maxLength={50}
                                                type="text"
                                                name="fullname"
                                                id="fullname"
                                                autoComplete="fullname"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={values.fullname || ""}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="dob" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Date of Birth <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="date"
                                                name="dob"
                                                id="dob"
                                                autoComplete="dob"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={dateFormat(values.dob, "yyyy-mm-dd") || ""}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Email Address (Optional)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                            value={values.email || ""}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="mobile_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Mobile Phone Number (Optional)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={15}
                                            id="mobile_no"
                                            name="mobile_no"
                                            type="mobile_no"
                                            autoComplete="mobile_no"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                            value={values.mobile_no || ""}
                                        />
                                    </div>
                                </div>


                                <div className="sm:col-span-4">
                                    <label htmlFor="height" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Height <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <span className="absolute inset-y-0 right-0 flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-3">cm</span>
                                            <input
                                                maxLength={3}
                                                type="text"
                                                name="height"
                                                id="height"
                                                autoComplete="height"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={values.height || ""}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Weight <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <span className="absolute inset-y-0 right-0 flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-3">kg</span>
                                            <input
                                                maxLength={3}
                                                type="text"
                                                name="weight"
                                                id="weight"
                                                autoComplete="weight"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={values.weight || ""}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <div className="mt-2">
                                        <fieldset>
                                            <legend className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Gender <span className="text-red-400 font-semibold">*</span></legend>
                                            <div className="mt-6 space-y-6">
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="male"
                                                        name="gender"
                                                        type="radio"
                                                        value="Male"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" onChange={handleChange}
                                                        checked={values.gender === "Male"}
                                                    />
                                                    <label htmlFor="male" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="female"
                                                        name="gender"
                                                        type="radio"
                                                        value="Female"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" onChange={handleChange}
                                                        checked={values.gender === "Female"}

                                                    />
                                                    <label htmlFor="female" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                        Female
                                                    </label>
                                                </div>
                                                <div className="flex items-center gap-x-3">
                                                    <input
                                                        id="rathernotsay"
                                                        name="gender"
                                                        type="radio"
                                                        value="Rather Not Say"
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" onChange={handleChange}
                                                        checked={values.gender === "Rather Not Say"}

                                                    />
                                                    <label htmlFor="rathernotsay" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                        Rather Not Say
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street_address" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Street address (As Identity Card) <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={90}
                                            type="text"
                                            name="street_address"
                                            id="street_address"
                                            autoComplete="street-address"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]" onChange={handleChange} value={values.street_address || ""}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Personal Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">Measure distance of cervical lordosis.</p>

                            <img
                                src={PatientMeasureInfo}
                                alt="Patient Measurement Info"
                                className="w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                width={2432}
                                height={1442}
                            />


                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="sagittal_suture" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        矢状缝-枕外嵴 Sagittal Suture - External Occipital Protuberance <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <span className="absolute inset-y-0 right-0 flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-3">cm</span>
                                            <input
                                                maxLength={3}
                                                type="text"
                                                name="sagittal_suture"
                                                id="sagittal_suture"
                                                autoComplete="sagittal_suture"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={values.sagittal_suture || ""}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="external_occipital_protuberance" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        枕外嵴-胸椎 External Occipital Protuberance - Thoracic Vertebrae <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <span className="absolute inset-y-0 right-0 flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-3">cm</span>
                                            <input
                                                maxLength={3}
                                                type="text"
                                                name="external_occipital_protuberance"
                                                id="external_occipital_protuberance"
                                                autoComplete="external_occipital_protuberance"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={values.external_occipital_protuberance || ""}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="both_shoulders_align" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        两侧肩膀对位 Aligning of both shoulders <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <span className="absolute inset-y-0 right-0 flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-3">cm</span>
                                            <input
                                                maxLength={3}
                                                type="text"
                                                name="both_shoulders_align"
                                                id="both_shoulders_align"
                                                autoComplete="both_shoulders_align"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" onChange={handleChange} value={values.both_shoulders_align || ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Additional Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">Extra details needed for TCH Pillow</p>



                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="purchasequantity" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Purchase Quantity <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <span className="absolute inset-y-0 right-0 flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-3">pcs</span>
                                            <input
                                                type="number" disabled
                                                name="purchasequantity"
                                                id="purchasequantity"
                                                autoComplete="purchasequantity"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:rounded disabled:bg-gray-200" onChange={handleChange} value={values.purchasequantity || "0"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Remarks (Optional)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={150}
                                            type="text"
                                            name="remarks"
                                            id="remarks"
                                            autoComplete="remarks"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]" onChange={handleChange} value={values.remarks || ""}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                        <NavLink end to={'..'} onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                Cancel
                            </button></NavLink>
                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>

        </div>
    )

}

export default EditTchPillowPatient;