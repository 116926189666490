import { useEffect, useState } from "react";
import SaveBreadcrumb from "../../Components/Layout/Breadcrumb/SaveBreadcrumb";
import DefaultLayout from "../Layout/DefaultLayout";
import axios from "axios";
import ValidateUserForm from "../../Util/ValidateUserForm";
import { NavLink, useNavigate } from "react-router-dom";
import User from "../../Model/User";
import { useCurrentUser } from "../../Providers/UserProvider";
import showErrorToast from "../../Util/Widgets/Toasts/ShowErrorToast";
import showSuccessToast from "../../Util/Widgets/Toasts/ShowSuccessToast";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import showLoadingToast from "../../Util/Widgets/Toasts/ShowLoadingToast";
import toast from "react-hot-toast";
import Select from 'react-select';

const AddUser = () => {

    useEffect(() => {
        document.title = "Create New User"
        getUser();
    }, []);

    const { currentUser } = useCurrentUser();
    const [user_related_agent, setUser_Related_Agent] = useState([]);
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;
    const [values, setValues] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        agent_no: '',
        fullname: '',
        is_admin: '0',
        role_id: ((currentUser as unknown as User).is_admin == "1") ? "1" : "2",
        email: '',
        mobile_no: '',
        country: 'Malaysia',
        street_address: '',
        remarks: '',
        related_agent: (currentUser as any as User).id,

    })

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if ((currentUser as unknown as User).is_admin) {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value,
            }));
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value,
                related_agent: (currentUser as unknown as User).id
            }));
        }
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        // Remove leading and trailing spaces
        const trimmedValue = value.trim();

        // Convert value to lowercase and remove spaces
        const sanitizedValue = trimmedValue.toLowerCase().replace(/\s/g, '');

        // Remove special characters using regex
        const sanitizedValueWithoutSpecialChars = sanitizedValue.replace(/[^\w]/g, '');

        // Update the state with the sanitized value
        setValues(prevValues => ({
            ...prevValues,
            username: sanitizedValueWithoutSpecialChars
        }));
    };

    //Submit Form
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        //Validation Form
        const username = values['username'];
        if (ValidateUserForm(values)) {
            try {
                showLoadingToast("Creating User...");
                const response = await axios.post(baseURL + '/checkUsername', { username });
                if (response.data.Error) {
                    showErrorToast(response.data.Error);
                }
                if (response.data.Success) {
                    try {
                        const addUserResponse = await axios.post(baseURL + '/addUser', values);
                        if (addUserResponse.data.Success) {
                            setTimeout(() => {
                                showSuccessToast(addUserResponse.data.Success);
                                navigate('/usermanagement');
                            }, 2000)

                        } else {
                            showErrorToast(addUserResponse.data.Error);
                        }

                    } catch (error) {
                        console.error('Error:', error);
                        showErrorToast('Error: ' + error);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
                showErrorToast('Error: ' + error);
            }
        }
    }

    const adminOptions = [{
        name: 'Administrator',
        value: '1',
    }, {
        name: 'Gold Agent',
        value: '2',
    },
    {
        name: 'Silver Agent',
        value: '3',
    },
    {
        name: 'Bronze Agent',
        value: '4',
    }]

    const agentOptions = [{
        name: 'Gold Agent',
        value: '2',
    },
    {
        name: 'Silver Agent',
        value: '3',
    },
    {
        name: 'Bronze Agent',
        value: '4',
    }
    ]

    const getUser = async () => {
        try {
            const response = await axios.get(baseURL + '/getAllUser/')
            if (response.data.Success) {

                setUser_Related_Agent(response.data.Success);
            } else {
                showErrorToast(response.data.Error);
            }
        } catch (error) {
            console.error('Error: ', error);
            showErrorToast('Error: ' + error);
        }
    }

    useEffect(() => {
        if (user_related_agent.length > 0) {
            setValues(prevValues => ({
                ...prevValues,
                related_agent: (user_related_agent[0] as unknown as User).id,
            }));
        }
    }, [user_related_agent]);

    const handleSelectChange = (selectedOption: any) => {
        setValues(prevValues => ({
            ...prevValues,
            related_agent: selectedOption ? selectedOption.value : ''
        }));
    };

    const selectOptions = user_related_agent.map((user: any) => ({
        value: user.id,
        label: user.fullname
    }));
    return (
        <div>
            <SaveBreadcrumb pageName="Create a New User" saveFunction={handleSubmit} />
            <div className='bg-white dark:bg-[#182235] px-3 py-5 sm:px-6 rounded-md'>
                <form onSubmit={handleSubmit}>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">User Account</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                Please provide necessary information to create user account.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Username <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                maxLength={20}
                                                type="text"
                                                name="username"
                                                id="username"
                                                autoComplete="username"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 items-center"
                                                onChange={handleUsernameChange} value={values.username}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Password <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2 relative rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            maxLength={50}
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            autoComplete="password"
                                            className="block w-full border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            onChange={handleChange}
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            {passwordVisible ? (
                                                <EyeSlashIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            ) : (
                                                <EyeIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Confirm Password <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2 relative rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            autoComplete="confirmPassword"
                                            className="block w-full border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            onChange={handleChange}
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            {passwordVisible ? (
                                                <EyeSlashIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            ) : (
                                                <EyeIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="role_id" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        User Role <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="role_id"
                                            name="role_id"
                                            autoComplete="role_id"
                                            className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange} value={values.role_id || "2"}
                                        >
                                            {((currentUser as unknown as User).is_admin == "1") ? adminOptions.map((adminoption, i) => (
                                                <option key={i} className='px-2 py-2' value={adminoption.value}>{adminoption.name}</option>
                                            )) : agentOptions.map((agentoption, i) => (
                                                <option key={i} className='px-2 py-2' value={agentoption.value}>{agentoption.name}</option>
                                            ))}

                                        </select>
                                    </div>
                                </div>

                                {(values.role_id !== "1") && <div className="sm:col-span-4">
                                    <label htmlFor="agent_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Authorised Agent No <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                maxLength={50}
                                                type="text"
                                                name="agent_no"
                                                id="agent_no"
                                                autoComplete="agent_no"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 items-center"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>}

                                {(currentUser as unknown as User).is_admin && (
                                    <div className="sm:col-span-4">
                                        <label htmlFor="related_agent" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Related Agent <span className="text-red-400 font-semibold">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <Select
                                                id="related_agent"
                                                name="related_agent"
                                                options={selectOptions}
                                                className="block w-full rounded-md border-0 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                onChange={handleSelectChange}
                                                placeholder="Select related agent"

                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Personal Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">Use a permanent address where you can receive mail.</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Fullname (As Identity Card) <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                maxLength={50}
                                                type="text"
                                                name="fullname"
                                                id="fullname"
                                                autoComplete="fullname"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Email address <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="mobile_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Mobile Phone Number <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={15}
                                            id="mobile_no"
                                            name="mobile_no"
                                            type="number"
                                            autoComplete="mobile_no"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Country <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        >
                                            <option className='px-2 py-2' value={"Malaysia"}>Malaysia</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street_address" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Street address <span className="text-red-400 font-semibold">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={90}
                                            type="text"
                                            name="street_address"
                                            id="street_address"
                                            autoComplete="street_address"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                {(currentUser as unknown as User).is_admin == "1" && <div className="col-span-full">
                                    <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Remarks (Optional)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={150}
                                            type="text"
                                            name="remarks"
                                            id="remarks"
                                            autoComplete="remarks"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]" onChange={handleChange}
                                        />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                        <NavLink end to={'..'} onClick={(e) => {
                            e.preventDefault();
                            navigate('/usermanagement');
                        }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                Cancel
                            </button></NavLink>

                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>

        </div>
    )

}

export default AddUser;