import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../../Utils/Transitions';
import { useCurrentUser } from '../../../Providers/UserProvider';
import axios from 'axios';
import showErrorToast from '../../../Util/Widgets/Toasts/ShowErrorToast';
import dateFormat from 'dateformat';
import io from 'socket.io-client';
import showSuccessToast from '../../../Util/Widgets/Toasts/ShowSuccessToast';
import LoadingWidget from '../../../Util/Widgets/LoadingWidget';

function DropdownNotifications({
  align
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(false); // Track new notifications

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const [loading, setLoading] = useState(true);
  const { currentUser } = useCurrentUser();
  const baseURL = process.env.REACT_APP_BASEURL;
  const socketURL = process.env.REACT_APP_SOCKETURL;

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
      // Hide red dot when closing dropdown
      setHasNewNotification(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
      // Hide red dot when closing dropdown
      setHasNewNotification(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const [notifications, setNotifications] = useState([]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    // Fetch notifications from the server
    getNotification();

    const socket = io(socketURL); // Adjust the URL based on your backend server

    // Listen for real-time notifications
    const handleNewNotification = (data) => {
      setNotifications(prevNotifications => [data.message, ...prevNotifications]);
      // Set flag for new notification
      if (currentUser.is_admin == "1") {
        setHasNewNotification(true);
      }
      getNotification();
    };

    // Debounce the handleNewNotification function with a delay of 1000ms
    const debouncedHandleNewNotification = debounce(handleNewNotification, 1000);

    socket.on('newNotification', debouncedHandleNewNotification);

    return () => {
      // Clean up socket event listener
      socket.off('newNotification', handleNewNotification);
      socket.disconnect();
    };
  }, []);

  const getNotification = async () => {
    try {
      const response = await axios.get((baseURL + `/getNotifications/${currentUser.role_id}}`))
      if (response.data.Success) {
        setNotifications(response.data.Success);
      } else {
        showErrorToast(response.data.Error);
      }
    } catch (error) {
      console.error('Error: ', error);
      showErrorToast('Error: ' + error);
    } finally {
      setLoading(false);
    }
  }

  const timeAgo = (timestamp) => {
    const now = new Date();
    const createdTime = new Date(timestamp);

    const diff = Math.abs(now - createdTime);
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (weeks >= 2) {
      // Show normal date when it is 2 weeks ago or more
      return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(createdTime);
    } else if (days >= 1) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours >= 1) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes >= 1) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return 'Just now';
    }
  };

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ${dropdownOpen && 'bg-slate-200'}`}
        aria-haspopup="true"
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
          // Hide red dot when opening dropdown
          setHasNewNotification(false);
        }}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Notifications</span>
        <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path className="fill-current text-slate-500 dark:text-slate-400" d="M6.5 0C2.91 0 0 2.462 0 5.5c0 1.075.37 2.074 1 2.922V12l2.699-1.542A7.454 7.454 0 006.5 11c3.59 0 6.5-2.462 6.5-5.5S10.09 0 6.5 0z" />
          <path className="fill-current text-slate-400 dark:text-slate-500" d="M16 9.5c0-.987-.429-1.897-1.147-2.639C14.124 10.348 10.66 13 6.5 13c-.103 0-.202-.018-.305-.021C7.231 13.617 8.556 14 10 14c.449 0 .886-.04 1.307-.11L15 16v-4h-.012C15.627 11.285 16 10.425 16 9.5z" />
        </svg>
        {hasNewNotification ? <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 border-2 border-white dark:border-[#182235] rounded-full"></div> : <div></div>}
      </button>

      <Transition
        className={`origin-top-right z-auto absolute top-full -mr-48 sm:mr-0 min-w-96 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-xl overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {(loading) ?
          <div className='py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center'>
            <LoadingWidget />
            <p className="mt-4 text-sm font-medium leading-6 text-gray-500 dark:text-white text-center">{`Loading Notification...`}</p>
          </div> :
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-4 border-b border-slate-300">Recent Notifications ({notifications.length})</div>
            <ul className="max-h-96 overflow-y-auto px-2">
              {(notifications.length > 0) ?
                notifications.map((noti, i) => (
                  <li key={i} className="border-b border-slate-200 dark:border-slate-700 last:border-0">
                    <Link
                      className="block py-4 px-4 hover:bg-slate-200 dark:hover:bg-slate-700/20"
                      to="#0"
                      onClick={() => {
                        setDropdownOpen(!dropdownOpen);
                        // Hide red dot when clicking on a notification
                        setHasNewNotification(false);
                      }}
                    >
                      <span className="block text-[12px]  text-indigo-300">New Activity </span>
                      <span className="block text-sm font-medium  dark:text-slate-500 mb-2">{noti.message}</span>
                      <span className="block text-xs text-slate-400 dark:text-slate-500 flex justify-between">
                        <span>{noti.fullname}</span>
                        <span>{timeAgo(noti.created_time)}</span>
                      </span>

                    </Link>
                  </li>
                )) :
                <div><p className="py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center">{`No notification.`}</p></div>}
            </ul>
          </div>}
      </Transition >
    </div >
  )
}

export default DropdownNotifications;