import { useEffect, useState } from "react";
import { useCurrentUser } from "../../Providers/UserProvider";
import { NavLink, useNavigate } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";
import UpdateBreadcumb from "../../Components/Layout/Breadcrumb/UpdateBreadcrumb";
import User from "../../Model/User";
import axios from "axios";
import showErrorToast from "../../Util/Widgets/Toasts/ShowErrorToast";
import showSuccessToast from "../../Util/Widgets/Toasts/ShowSuccessToast";
import showLoadingToast from "../../Util/Widgets/Toasts/ShowLoadingToast";


const ChangePassword = () => {
    const { currentUser } = useCurrentUser();

    useEffect(() => {
        document.title = "Change Password"
    }, []);

    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const baseURL = process.env.REACT_APP_BASEURL;

    const handleUpdate = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            if (password !== confirmPassword) {
                showErrorToast("Password not match. Please try again");
            } else {
                const id = (currentUser as unknown as User).id;
                const response = await axios.post(baseURL + '/checkPassword', { id, password });
                if (response.data.Error) {
                    showErrorToast(response.data.Error);
                }
                if (response.data.Success) {
                    try {
                        showLoadingToast("Updating Password...")
                        const changePassResponse = await axios.put(baseURL + '/changeUserPassword/' + id, { newPassword });
                        if (changePassResponse.data.Error) {
                            showErrorToast(response.data.Error);
                        }
                        if (changePassResponse.data.Success) {
                            setTimeout(() => {
                                showSuccessToast(changePassResponse.data.Success);
                                navigate('/viewProfile');
                            }, 1000)
                        }
                    } catch (error) {
                        console.error('Error:', error);
                        showErrorToast('Error:' + error);
                    }
                }
            }

        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    return (
        <div>
            <div>
                <UpdateBreadcumb pageName="Change Password" updateFunction={handleUpdate} />
                <div className='bg-white dark:bg-[#182235] px-3 py-5 sm:px-6 rounded-md'>
                    <form onSubmit={handleUpdate}>
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Change Current Password</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                    Changing password by entering your current password and new password.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                            Current Password
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    required
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    autoComplete="password"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    onChange={(e: any) => setPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                            Confirm Current Password
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    required
                                                    type="password"
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    autoComplete="confirmPassword"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                            New Password
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    required
                                                    type="password"
                                                    name="newPassword"
                                                    id="newPassword"
                                                    autoComplete="newPassword"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    onChange={(e: any) => setNewPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                            <NavLink end to={'..'} onClick={(e) => {
                                e.preventDefault();
                                navigate('/viewProfile');
                            }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                    Cancel
                                </button></NavLink>

                            <button
                                type="submit"
                                className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>

    );
}

export default ChangePassword;