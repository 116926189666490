import { useEffect, useState } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import User from '../../Model/User';
import axios from 'axios';
import AlertDialog from '../../Components/AlertDialog';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon, LockClosedIcon, PencilIcon } from '@heroicons/react/20/solid';
import emailjs from '@emailjs/browser';
import showErrorToast from '../../Util/Widgets/Toasts/ShowErrorToast';
import showSuccessToast from '../../Util/Widgets/Toasts/ShowSuccessToast';
import { useCurrentUser } from '../../Providers/UserProvider';
import dateFormat from 'dateformat';
import Patient_Pillow from '../../Model/Patient_Pillow';

const ViewUser = () => {
    useEffect(() => {
        document.title = "View User"
    }, []);

    const { id } = useParams();
    const [user, setUser] = useState<User[]>([]);
    const [patients, setPatients] = useState<Patient_Pillow[]>([]);
    const [relatedAgent, setRelatedAgent] = useState<User[]>([]);
    const { currentUser } = useCurrentUser();

    const baseURL = process.env.REACT_APP_BASEURL;
    const emailJSPrivateKey = process.env.REACT_APP_EMAILJS_PRIVATEKEY;
    const emailJSServiceID = process.env.REACT_APP_EMAILJS_SERVICEID_GMAIL;
    const emailJSTemplateID = process.env.REACT_APP_EMAILJS_TEMPLATEID_RESETPASS;

    const getUserData = async () => {
        try {
            const response = await axios.get(baseURL + '/getUserById/' + id)
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setUser(response.data.Success[0]);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    const getPatientData = async () => {
        try {
            const response = await axios.get(baseURL + '/getAllPatient_PillowByOwnRelatedAgentId/' + id)
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setPatients(response.data.Success);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    useEffect(() => {
        getUserData();
        getPatientData();
        emailjs.init(emailJSPrivateKey || '');
    }, [])

    //Dialog
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const openDialog = () => { setDialogIsOpen(true); }
    const closeDialog = () => setDialogIsOpen(false);

    const handleResetPasswordOnClick = () => {
        openDialog();
    }

    const navigate = useNavigate();

    const handleResetPassword = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const newPassword = generateRandomString(12);
            const response = await axios.put(baseURL + '/resetPassword/' + id, { newPassword });
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                await emailjs.send(emailJSServiceID || '', emailJSTemplateID || '', {
                    fullname: (user as unknown as User).fullname,
                    tempPassword: newPassword,
                    user_email: (user as unknown as User).email,
                });
                showSuccessToast(response.data.Success);
                closeDialog();

            }

        } catch (error) {
            console.error('Error: ', error);
            showErrorToast('Error: ' + error);
        } finally {
        }
    }

    // Function to generate a random string of a specified length
    const generateRandomString = (length: any) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
        return randomString;
    };

    return (
        <div>
            <div>
                <div>
                    <div className="flex justify-start mb-5">
                        <NavLink end to={'..'} onClick={(e) => {
                            e.preventDefault();
                            navigate('/usermanagement');
                        }} className="flex items-center">
                            <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-500 dark:fill-gray-400" aria-hidden="true" />
                            <p className="text-sm text-gray-500 dark:text-gray-400 sm:truncate sm:tracking-tight">Back to User Management</p>
                        </NavLink>


                    </div>
                    {((currentUser as unknown as User).id == (user as unknown as User).id) ? <div></div> : <div className="lg:flex lg:items-center lg:justify-between mb-5">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:tracking-tight">
                                {(user as unknown as User).fullname}
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className=" sm:block">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white dark:bg-[#182235] px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700"
                                    onClick={() => handleResetPasswordOnClick()}
                                >
                                    <LockClosedIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-grey-400" aria-hidden="true" />
                                    Reset Password
                                </button>
                            </span>
                            <span className="ml-3">
                                <NavLink end to={`/editUser/${(user as unknown as User).id}`}>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                        Edit
                                    </button></NavLink>
                            </span>

                        </div>
                    </div>}

                </div>
                <div className='bg-white dark:bg-[#182235] px-4 py-6 sm:px-6 sm:py-8 rounded-md'>
                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-bold leading-7 text-gray-900 dark:text-gray-100">User Information</h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Personal details and application.</p>
                    </div>
                    <div className="mt-6 border-t border-gray-100 dark:border-gray-700">
                        <dl className="divide-y divide-gray-100 dark:divide-gray-700">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Fullname</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(user as unknown as User).fullname}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Email Address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(user as unknown as User).email || "-"}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Mobile Phone Number</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(user as unknown as User).mobile_no || "-"}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Role</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(user as unknown as User).rolename}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Street Address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(user as unknown as User).street_address || "-"}</dd>
                            </div>
                            {(currentUser as unknown as User).is_admin == "1" && <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Remarks</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(user as unknown as User).remarks || "-"}</dd>
                            </div>}

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Joined Date</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{dateFormat((user as unknown as User).created_time, "mmm dd, yyyy")}</dd>
                            </div>

                        </dl>
                    </div>

                    <div className="mt-16 px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Account Information</h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Account details, profile and more.</p>
                    </div>
                    <div className="mt-6 border-t border-gray-100 dark:border-gray-700">
                        <dl className="divide-y divide-gray-100 dark:divide-gray-700">

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Username</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(user as unknown as User).username}</dd>
                            </div>

                            {((user as unknown as User).is_admin == "0") && <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Authorized Agent No</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{((user as unknown as User).agent_no) || "-"}</dd>
                            </div>}

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Related Agent ID</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{((user as unknown as User).related_agent) ? (user as unknown as User).related_agent : `Not Assigned`}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Related Agent Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{((user as unknown as User).agent_fullname) ? (user as unknown as User).agent_fullname : `Not Assigned`}</dd>
                            </div>


                        </dl>
                    </div>

                    {(currentUser as any as User).is_admin == "1" && <div>
                        <div className="mt-16 px-4 sm:px-0">
                            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Agent's Patient (TCH Pillow) ({patients.length})</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Agent related tch pillow patient list.</p>
                        </div>
                        <div className="mt-6 border-t border-gray-100 dark:border-gray-700">
                            <dl className="divide-y divide-gray-100 dark:divide-gray-700">
                                {(patients.length === 0)
                                    ?
                                    <p className="py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center">No patient found.</p>
                                    :
                                    <div className="mt-8 flow-root">
                                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                    Name
                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    Purchase Quantity
                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    Order Status
                                                                </th>
                                                                <th scope="col" className="hidden shrink-0 sm:flex relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                    <span className="sr-only">Edit</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {patients.map((patient) => (
                                                                <tr key={patient.id}>
                                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                        {patient.fullname}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{patient.purchasequantity}</td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{patient.order_status || "-"}</td>
                                                                    <td className="hidden shrink-0 sm:flex relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                        <a href={"/viewTchPillowPatient/" + patient.id} className="text-indigo-600 hover:text-indigo-900">
                                                                            View<span className="sr-only">, {patient.fullname}</span>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </dl>
                        </div>
                    </div>}
                </div>
            </div>
            {/* <div className='bg-white dark:bg-[#182235] px-4 py-5 sm:px-6 rounded-md h-96 content-center'><p className="text-sm leading-6 text-gray-600 dark:text-gray-400 text-center">
                 You have no permission to view this user.
             </p></div> */}

            <AlertDialog open={dialogIsOpen} onClose={closeDialog} title={"Reset Password"} message={`Do you want to reset ${(user as unknown as User).fullname}'s password? \n\n New password will be sent to ${(user as unknown as User).fullname}'s email.`} handleDeleteFunction={handleResetPassword} />


        </div>

    );
}

export default ViewUser;