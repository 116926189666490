import { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import UpdateBreadcumb from "../../Components/Layout/Breadcrumb/UpdateBreadcrumb";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import User from "../../Model/User";
import axios from "axios";
import showSuccessToast from "../../Util/Widgets/Toasts/ShowSuccessToast";
import showErrorToast from "../../Util/Widgets/Toasts/ShowErrorToast";
import ValidateUpdateUserForm from "../../Util/ValidateUpdateUserForm";
import { useCurrentUser } from "../../Providers/UserProvider";
import showLoadingToast from "../../Util/Widgets/Toasts/ShowLoadingToast";
import toast from "react-hot-toast";

const EditUser = () => {

    useEffect(() => {
        document.title = "Edit User"
    }, []);

    const { id } = useParams();
    const [user, setUser] = useState<User[]>([]);
    const { currentUser } = useCurrentUser();
    const [user_related_agent, setUser_Related_Agent] = useState([]);
    const [values, setValues] = useState({
        username: (user as unknown as User).username,
        fullname: (user as unknown as User).fullname,
        agent_no: (user as unknown as User).agent_no,
        role_id: (user as unknown as User).role_id,
        email: (user as unknown as User).email,
        mobile_no: (user as unknown as User).mobile_no,
        country: (user as unknown as User).country,
        street_address: (user as unknown as User).street_address,
        remarks: (user as unknown as User).remarks,
        related_agent: (user as unknown as User).related_agent,
    });
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;

    useEffect(() => {
        getUserData();
        getAllUser();
    }, [])

    const getUserData = async () => {
        try {
            const response = await axios.get(baseURL + '/getUserById/' + id);
            if (response.data.Success) {
                setUser(response.data.Success[0]);
                setValues({ ...values, username: response.data.Success[0].username, agent_no: response.data.Success[0].agent_no, role_id: response.data.Success[0].role_id, fullname: response.data.Success[0].fullname, email: response.data.Success[0].email, mobile_no: response.data.Success[0].mobile_no, country: response.data.Success[0].country, street_address: response.data.Success[0].street_address, remarks: response.data.Success[0].remarks, related_agent: response.data.Success[0].related_agent })
            }
        } catch (error) {
            console.error('Get Data Error: ' + error);
            showErrorToast('Get Data Error: ' + error);
        }
    }

    const handleUpdate = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (ValidateUpdateUserForm(values)) {
            try {
                showLoadingToast("Updating User...")
                const response = await axios.put(baseURL + '/updateUser/' + id, values)
                if (response.data.Error) {
                    showErrorToast(response.data.Error);
                }
                if (response.data.Success) {
                    setTimeout(() => {
                        showSuccessToast(response.data.Success);
                        navigate(-1);
                    }, 2000)
                }
            } catch (error) {
                showErrorToast(error);
            }
        }
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const getRelatedAgentId = () => {
        if (!(user as unknown as User).related_agent) {
            return "0";
        } else {
            return (user as unknown as User).related_agent;
        }
    }

    const getAllUser = async () => {
        try {
            const response = await axios.get(baseURL + '/getAllUser/')
            if (response.data.Success) {

                setUser_Related_Agent(response.data.Success);
            } else {
                showErrorToast(response.data.Error);
            }
        } catch (error) {
            console.error('Error: ', error);
            showErrorToast('Error: ' + error);
        }
    }

    useEffect(() => {
        if (!values.related_agent && user_related_agent.length > 0) {
            setValues(prevValues => ({
                ...prevValues,
                related_agent: (user_related_agent[0] as unknown as User).id,
            }));
        }
    }, [user_related_agent])

    const adminOptions = [{
        name: 'Administrator',
        value: '1',
    }, {
        name: 'Gold Agent',
        value: '2',
    },
    {
        name: 'Silver Agent',
        value: '3',
    },
    {
        name: 'Bronze Agent',
        value: '4',
    }]

    const agentOptions = [{
        name: 'Gold Agent',
        value: '2',
    },
    {
        name: 'Silver Agent',
        value: '3',
    },
    {
        name: 'Bronze Agent',
        value: '4',
    }
    ]

    return (
        <div>

            <div>
                {((currentUser as unknown as User).id == (user as unknown as User).id) ?
                    <div className='bg-white dark:bg-[#182235] px-4 py-5 sm:px-6 rounded-md h-96 content-center'><p className="text-sm leading-6 text-gray-600 dark:text-gray-400 text-center">
                        Please edit your personal profile in user profile tab.
                    </p></div> :
                    <div>
                        <UpdateBreadcumb pageName="Update User" updateFunction={handleUpdate} />
                        <div className='bg-white dark:bg-[#182235] px-3 py-5 sm:px-6 rounded-md'>
                            <form onSubmit={handleUpdate}>
                                <div className="space-y-12">
                                    <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                                        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">User Account</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                            Please provide necessary information to create user account.
                                        </p>

                                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="sm:col-span-4">
                                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                                    Username <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                        <input
                                                            disabled
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            autoComplete="username"
                                                            className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 items-center disabled:rounded disabled:bg-gray-200"
                                                            onChange={handleChange}
                                                            value={(values.username) || "-"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4">
                                                <label htmlFor="agent_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                                    Authorized Agent No. <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                        <input
                                                            disabled
                                                            type="text"
                                                            name="agent_no"
                                                            id="agent_no"
                                                            autoComplete="agent_no"
                                                            className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 items-center disabled:rounded disabled:bg-gray-200"
                                                            onChange={handleChange}
                                                            value={(values.agent_no) || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="role_id" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                    User Role <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <select disabled={currentUser.is_admin != "1"}
                                                        id="role_id"
                                                        name="role_id"
                                                        autoComplete="role_id"
                                                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                        onChange={handleChange}
                                                        value={values.role_id}
                                                    >
                                                        {((currentUser as unknown as User).is_admin == "1") ? adminOptions.map((adminoption, i) => (
                                                            <option key={i} className='px-2 py-2' value={adminoption.value}>{adminoption.name}</option>
                                                        )) : agentOptions.map((agentoption, i) => (
                                                            <option key={i} className='px-2 py-2' value={agentoption.value}>{agentoption.name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>

                                            {(currentUser as unknown as User).is_admin ? <div className="sm:col-span-4">
                                                <label htmlFor="related_agent" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                    Related Agent <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <select
                                                        id="related_agent"
                                                        name="related_agent"
                                                        autoComplete="related_agent"
                                                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                        onChange={handleChange} value={values.related_agent}
                                                    >
                                                        {user_related_agent.map((user, i) => (
                                                            <option key={i} className='px-2 py-2' value={(user as unknown as User).id}>{(user as unknown as User).fullname}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div> : <div></div>}

                                        </div>
                                    </div>

                                    <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                                        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Personal Information</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">Use a permanent address where you can receive mail.</p>

                                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="sm:col-span-4">
                                                <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                                    Fullname (As Identity Card) <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                        <input
                                                            maxLength={50}
                                                            type="text"
                                                            name="fullname"
                                                            id="fullname"
                                                            autoComplete="fullname"
                                                            className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                            onChange={handleChange}
                                                            value={values.fullname || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4">
                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                    Email address <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                        onChange={handleChange}
                                                        value={values.email || ""}
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4">
                                                <label htmlFor="mobile_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                    Mobile Phone Number <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        maxLength={15}
                                                        id="mobile_no"
                                                        name="mobile_no"
                                                        type="number"
                                                        autoComplete="mobile_no"
                                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                        onChange={handleChange}
                                                        value={values.mobile_no || ""}
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                    Country
                                                </label>
                                                <div className="mt-2">
                                                    <select
                                                        id="country"
                                                        name="country"
                                                        autoComplete="country_name"
                                                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                        onChange={handleChange}
                                                        value={(user as unknown as User).country || "-"}
                                                    >
                                                        <option className='px-2 py-2' value={"Malaysia"}>Malaysia</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-span-full">
                                                <label htmlFor="street_address" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                    Street Address <span className="text-red-400 font-semibold">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        maxLength={90}
                                                        type="text"
                                                        name="street_address"
                                                        id="street_address"
                                                        autoComplete="street_address"
                                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                        onChange={handleChange}
                                                        value={values.street_address || ""}
                                                    />
                                                </div>
                                            </div>

                                            {(currentUser as unknown as User).is_admin == "1" && <div className="col-span-full">
                                                <label htmlFor="remarks" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                    Remarks (Optional)
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        maxLength={90}
                                                        type="text"
                                                        name="remarks"
                                                        id="remarks"
                                                        autoComplete="remarks"
                                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                                        onChange={handleChange}
                                                        value={values.remarks || ""}
                                                    />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                                    <NavLink end to={'..'} onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                            Cancel
                                        </button></NavLink>

                                    <button
                                        type="submit"
                                        className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>}
            </div>


        </div>
    )

}

export default EditUser;