import { Fragment, SetStateAction, useEffect, useState } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import TwoActionsBreadcrumb from '../../Components/Layout/Breadcrumb/TwoActionsBreadcrumb';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EyeIcon, FunnelIcon, PencilSquareIcon, PhotoIcon, ShoppingBagIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/20/solid';
import AlertDialog from '../../Components/AlertDialog';
import Patient_Pillow from '../../Model/Patient_Pillow';
import User from '../../Model/User';
import showErrorToast from '../../Util/Widgets/Toasts/ShowErrorToast';
import showSuccessToast from '../../Util/Widgets/Toasts/ShowSuccessToast';
import { useCurrentUser } from '../../Providers/UserProvider';
import LoadingWidget from '../../Util/Widgets/LoadingWidget';
import dateFormat from 'dateformat';
import { Menu, Transition } from '@headlessui/react'

const filters = [
    { name: "All", value: "all" },
    { name: "This Month", value: "this_month" },
    { name: "Last Month", value: "last_month" },
];

const TchPillowPatientManagement = () => {
    useEffect(() => {
        document.title = "Patient Management | TCH Pillow"
    }, []);

    const [patients, setPatient] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useCurrentUser();
    const baseURL = process.env.REACT_APP_BASEURL;

    useEffect(() => {
        // getPatient((currentUser as unknown as User).id);
        //fetchPatients((currentUser as unknown as User).id);
    }, [])

    const getPatient = async (id: any) => {
        try {
            let getAllPatientURL = ((currentUser as unknown as User).is_admin == "1") ? (baseURL + '/getAllPatient_Pillow/')
                : (baseURL + `/getAllPatient_PillowByOwnRelatedAgentId/${id}}`)

            const response = await axios.get(getAllPatientURL)
            if (response.data.Success) {
                setPatient(response.data.Success);
            } else {
                showErrorToast(response.data.Error);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        } finally {
            setLoading(false);
        }
    }

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const patientsList = patients.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(patients.length / recordsPerPage);
    const pageNumbers = [...Array(nPage + 1).keys()].slice(1);

    //Delete
    //Dialog
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [currentUserId, setCurrentUserId] = useState();
    const [currentUserFullname, setCurrentUserFullname] = useState();
    const openDialog = (id: any, fullname: any) => { setDialogIsOpen(true); setCurrentUserId(id); setCurrentUserFullname(fullname); }
    const closeDialog = () => setDialogIsOpen(false);

    const handleDeleteOnClick = (id: any, fullname: String) => {
        openDialog(id, fullname);
    }

    const handleDelete = async () => {
        try {
            const response = await axios.delete(baseURL + '/deletePatient_Pillow/' + currentUserId);
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                window.location.reload();
                showSuccessToast(response.data.Success);

            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error: ' + error);
        }
    }

    //Search
    const [query, setQuery] = useState("");

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    //Filter 
    const [currentFilterName, setCurrentFilterName] = useState("All"); // Set default filter name to "All"
    const [currentFilterValue, setCurrentFilterValue] = useState(null); // Set default filter value to null

    const getPatientFilter = async (id: any, filter: any) => {
        setLoading(true);
        try {
            const isAdmin = currentUser.is_admin == "1";
            let getAllPatientURL = `${baseURL}/getAllPatient_PillowFilter/${id}?isAdmin=${isAdmin ? "1" : "0"}`;

            if (filter && filter !== "all") {
                getAllPatientURL += `&filter=${filter}`;
            }

            const response = await axios.get(getAllPatientURL);
            if (response.data.Success) {
                setPatient(response.data.Success);
            } else {
                showErrorToast(response.data.Error);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (filter: any) => {
        setCurrentFilterValue(filter.value);

        // Update currentFilterName based on the selected filter
        switch (filter.value) {
            case "this_month":
                setCurrentFilterName(`This Month (${getCurrentMonthName()})`);
                break;
            case "last_month":
                setCurrentFilterName(`Last Month (${getLastMonthName()})`);
                break;
            default:
                setCurrentFilterName(filter.name);
        }

        getPatientFilter(currentUser.id, filter.value);
    };

    useEffect(() => {
        getPatientFilter(currentUser.id, currentFilterValue);
    }, []);

    // Function to get the name of the current month
    const getCurrentMonthName = () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const currentMonthIndex = new Date().getMonth();
        return months[currentMonthIndex];
    };

    // Function to get the name of the last month
    const getLastMonthName = () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const lastMonthIndex = (new Date().getMonth() - 1 + 12) % 12; // Handling edge case for January
        return months[lastMonthIndex];
    };

    return (
        <div>
            <div>
                <div className="lg:flex lg:items-center lg:justify-between mb-5">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:tracking-tight">
                            Patient Management (TCH Pillow)
                        </h2>
                        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                Seamlessly Organize and Monitor Patient Data for Enhanced Care
                            </div>

                        </div>
                    </div>
                    {/* <div className="mt-5 flex lg:ml-4 lg:mt-0">

                        {((currentUser as unknown as User).is_admin == "1") && <NavLink end to={"/dispatchTchPillowManagement"}>
                            <span className=" sm:block">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white dark:bg-[#182235] px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700"
                                >
                                    Order Dispatch
                                </button>
                            </span>
                        </NavLink>}

                        <NavLink end to={"/addTchPillowPatientImages"}>
                            <span className="ml-3 sm:block">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white dark:bg-[#182235] px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700"
                                >
                                    Add Patient Images
                                </button>
                            </span>
                        </NavLink>

                        <NavLink end to={"/addTchPillowPatient"}>
                            <span className="ml-3">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Create New Patient
                                </button>
                            </span>
                        </NavLink>
                    </div> */}
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="hidden sm:block">
                            {((currentUser as unknown as User).is_admin == "1") && <NavLink end to={"/dispatchTchPillowManagement"}><button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <ShoppingBagIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                Order Dispatch
                            </button></NavLink>}
                        </span>

                        <span className="ml-3 hidden sm:block">
                            <NavLink end to={"/addTchPillowPatientImages"}><button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <PhotoIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                Add Patient Images
                            </button></NavLink>
                        </span>

                        <span className="sm:ml-3">
                            <NavLink end to={"/addTchPillowPatient"}><button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <UserPlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                Add Patient
                            </button></NavLink>
                        </span>
                        {/* Dropdown */}
                        <Menu as="div" className="relative ml-3 sm:hidden">
                            <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                                More
                                <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>

                            <Transition
                                enter="transition ease-out duration-200"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {((currentUser as unknown as User).is_admin == "1") && <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="/dispatchTchPillowManagement"
                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                            >
                                                Order Dispatch
                                            </a>
                                        )}
                                    </Menu.Item>}
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="/addTchPillowPatientImages"
                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                            >
                                                Add Patient Images
                                            </a>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>

                </div>
            </div>
            <div className="bg-white dark:bg-[#182235] px-4 py-3 sm:px-6 rounded-md">
                <div className="border-b border-gray-900/10 dark:border-gray-50/10 py-4 sm:py-4 lg:flex justify-between">
                    <div className="flex rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-xl">
                        <input
                            type="text"
                            name="searchpatient"
                            id="searchpatient"
                            autoComplete="searchpatient"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Search"
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </div>
                    <Menu as="div" className="relative inline-block text-left mt-4 lg:mt-0 z-10">
                        <div>
                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-white px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 items-center">
                                <FunnelIcon className="ml-1 h-4 w-4 text-indigo-200" aria-hidden="true" />
                                {currentFilterName}
                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>
                        </div>

                        <Transition
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute lg:right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    {filters.map((filter, i) => (
                                        <Menu.Item key={i}>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => handleFilterChange(filter)}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block w-full px-4 py-2 text-xs text-left' // Adjusted styles for full-width button
                                                    )}
                                                >
                                                    {filter.name}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    {/* <p className="text-sm font-semibold leading-6 text-indigo-600 dark:text-indigo-200 lg:mt-0 mt-2">+ Add Filter</p> */}
                </div>
                <ul className="divide-y divide-gray-100 dark:divide-gray-600">
                    {(loading) ?
                        <div className='py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center'>
                            <LoadingWidget />
                            <p className="mt-4 text-sm font-medium leading-6 text-gray-500 dark:text-white text-center">{`Loading Patient Data...`}</p>
                        </div>
                        :
                        (patientsList.length > 0)
                            ? patientsList.filter((patient) =>
                            (patient.fullname.toLowerCase().includes(query) ||
                                dateFormat(patient.dob, "mm-dd-yyyy").toLowerCase().includes(query) ||
                                dateFormat(patient.dob, "mmddyyyy").toLowerCase().includes(query))).map((patient, i) =>
                                (<li key={patient.id} className="flex gap-x-6 py-5 justify-between">
                                    <div className="flex min-w-0 gap-x-4">
                                        <div className="hidden sm:flex relative inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-indigo-100 rounded-full dark:bg-gray-600">
                                            <span className="font-medium text-gray-600 dark:text-gray-300">{patient.fullname[0]}</span>
                                        </div>
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">{patient.fullname}</p>
                                            <p className="truncate text-xs leading-5 text-gray-500 dark:text-gray-400">Date of Birth: {dateFormat((patient as unknown as Patient_Pillow).dob, "mm-dd-yyyy")}</p >
                                        </div>
                                    </div>
                                    <div className=" hidden shrink-0 sm:flex sm:items-center gap-6 mr-6">
                                        {(patient.order_status) && <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">{patient.order_status}</span>}

                                        {(patient.purchasequantity > 1) ? <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                            {patient.purchasequantity} Pillows
                                        </span> : <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                            {patient.purchasequantity} Pillow
                                        </span>}
                                        <NavLink end to={`/editTchPillowPatient/${patient.id}`}><PencilSquareIcon className="h-4 w-4" /></NavLink>
                                        {(patient.order_status) ? <TrashIcon className="h-4 w-4 text-gray-400" aria-hidden="true" /> : <TrashIcon className="h-4 w-4" aria-hidden="true" onClick={() => handleDeleteOnClick(patient.id, patient.fullname)} />}
                                        <NavLink end to={`/viewTchPillowPatient/${patient.id}`} key={i}><EyeIcon className='h-4 w-4' aria-hidden="true" /></NavLink>
                                    </div>
                                    <Menu as="div" className="relative ml-3 sm:hidden">
                                        <Menu.Button className="inline-flex items-center rounded-md bg-white px-4 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                                            More
                                            <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </Menu.Button>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <NavLink end to={`/editTchPillowPatient/${patient.id}`}>
                                                            <a
                                                                href="#"
                                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                Edit
                                                            </a></NavLink>
                                                    )}
                                                </Menu.Item>
                                                {(!patient.order_status) && <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="#" onClick={() => handleDeleteOnClick(patient.id, patient.fullname)}
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        >
                                                            Delete
                                                        </a>
                                                    )}
                                                </Menu.Item>}
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <NavLink end to={`/viewTchPillowPatient/${patient.id}`}>
                                                            <a
                                                                href="#"
                                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                View
                                                            </a></NavLink>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </li>)) : <p className="py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center">No patient found.</p>}

                </ul>
                <AlertDialog open={dialogIsOpen} onClose={closeDialog} title={"Delete Patient"} message={"Confirm delete " + currentUserFullname + " from database?"} handleDeleteFunction={handleDelete} />

                <div>
                    <div className="flex flex-1 justify-between sm:hidden mt-4">
                        <a
                            onClick={prePage}
                            className="relative inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-gray-300"
                        >
                            Previous
                        </a>
                        <a
                            onClick={nextPage}
                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-gray-300"
                        >
                            Next
                        </a>
                    </div>
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between mt-8">
                        <div>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                Showing <span className="font-medium">{firstIndex + 1}</span> to <span className="font-medium">{lastIndex}</span> of{' '}
                                <span className="font-medium">{patients.length}</span> results
                            </p>
                        </div>
                        <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                <a
                                    onClick={prePage}
                                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:z-20 focus:outline-offset-0"
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                </a>
                                {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                                {
                                    pageNumbers.map((n, i) => (
                                        <a

                                            aria-current="page"
                                            key={i}
                                            className={`${currentPage === n ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:z-20 focus:outline-offset-0'}`}
                                            onClick={() => changeCurrentPage(n)}
                                        >
                                            {n}
                                        </a>
                                    ))
                                }

                                <a
                                    onClick={nextPage}
                                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:z-20 focus:outline-offset-0"
                                >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );


    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    function changeCurrentPage(id: SetStateAction<number>) {
        setCurrentPage(id);
    }

    function nextPage() {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1);
        }
    }
}

export default TchPillowPatientManagement;