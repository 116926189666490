// SessionTimeout.js

import React, { useEffect } from 'react';
import { useAuth } from '../../Providers/AuthProvider';
import { Outlet } from 'react-router-dom';

const SessionTimeout = () => {
  const auth = useAuth();
  const TIMEOUT_DURATION = 100 * 60 * 1000; // 1 hour in milliseconds
  let timeout: NodeJS.Timeout;

  const handleLogout = () => {
    auth.logOut();
  };

  const handleUserActivity = () => {
    clearTimeout(timeout);
    timeout = setTimeout(handleLogout, TIMEOUT_DURATION);
  };

  // const handleBeforeUnload = (e: any) => {
  //   handleLogout();
  //   e.preventDefault();
  //   e.returnValue = '';
  // };

  useEffect(() => {
    timeout = setTimeout(handleLogout, TIMEOUT_DURATION);
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    // window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  return <Outlet />; // This component doesn't render anything visible
};

export default SessionTimeout;
