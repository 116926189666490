
import { useEffect, useState } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import { useCurrentUser } from '../../Providers/UserProvider';
import User from '../../Model/User';
import { ArrowLeftIcon, PencilIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import showErrorToast from '../../Util/Widgets/Toasts/ShowErrorToast';

const ViewProfile = () => {
    const { currentUser } = useCurrentUser();
    const [relatedAgent, setRelatedAgent] = useState<User[]>([]);
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;

    useEffect(() => {
        document.title = "View Profile"
    }, []);

    return (
        <div>
            <div>
                <div className="flex justify-start mb-5">
                    <NavLink end to={'..'} onClick={(e) => {
                        e.preventDefault();
                        navigate('/home');
                    }} className="flex items-center">
                        <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-500 dark:fill-gray-400" aria-hidden="true" />
                        <p className="text-sm text-gray-500 dark:text-gray-400 sm:truncate sm:tracking-tight">Back</p>
                    </NavLink>


                </div>
                <div className="lg:flex lg:items-center lg:justify-between mb-5">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:tracking-tight">
                            My Profile
                        </h2>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="ml-3 hidden sm:block">
                            <NavLink end to={'/changePassword'}>
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white dark:bg-[#182235] px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700"

                                >
                                    <LockClosedIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-grey-400" aria-hidden="true" />
                                    Change Password
                                </button></NavLink>
                        </span>
                        <span className="sm:ml-3">
                            <NavLink end to={'/editProfile'}>
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                    Edit
                                </button></NavLink>
                        </span>

                    </div>
                </div>
            </div>
            <div className='bg-white dark:bg-[#182235] px-4 py-5 sm:px-6 rounded-md'>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">My Information</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Personal details and application.</p>
                </div>
                <div className="mt-6 border-t border-gray-100 dark:border-gray-700">
                    <dl className="divide-y divide-gray-100 dark:divide-gray-700">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Fullname</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).fullname}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Email Address</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).email}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Mobile Phone Number</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).mobile_no}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Role</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).rolename}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Street Address</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).street_address}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Country</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).country}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Joined Date</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).created_time?.substring(0, 10)}</dd>
                        </div>

                    </dl>
                </div>

                <div className="mt-16 px-8 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Account Information</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Account details, profile and more.</p>
                </div>
                <div className="mt-6 border-t border-gray-100 dark:border-gray-700">
                    <dl className="divide-y divide-gray-100 dark:divide-gray-700">

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Username</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).username}</dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Authorized Agent No</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(currentUser as unknown as User).agent_no || '-'}</dd>
                        </div>

                    </dl>
                </div>
            </div>

        </div>

    );
}

export default ViewProfile;