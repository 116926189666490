import ViewBreadcrumb from '../../Components/Layout/Breadcrumb/ViewBreadcrumb';
import { useEffect, useState } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import Patient_Pillow from '../../Model/Patient_Pillow';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import dateFormat from 'dateformat';
import AlertDialog from '../../Components/AlertDialog';
import { useCurrentUser } from '../../Providers/UserProvider';
import User from '../../Model/User';
import showErrorToast from '../../Util/Widgets/Toasts/ShowErrorToast';
import showSuccessToast from '../../Util/Widgets/Toasts/ShowSuccessToast';
import { ArrowLeftIcon, PencilIcon } from '@heroicons/react/20/solid';


const ViewTchPillowPatient = () => {
    useEffect(() => {
        document.title = "View Patient"
    }, []);

    const { id } = useParams();
    const [patient, setPatient] = useState<Patient_Pillow[]>([]);
    const [images, setImages] = useState([]);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const openDialog = (id: any) => { setDialogIsOpen(true); setCurrentImageId(id); }
    const closeDialog = () => setDialogIsOpen(false);
    const { currentUser } = useCurrentUser();
    const [currentImageId, setCurrentImageId] = useState([]);
    const baseURL = process.env.REACT_APP_BASEURL;

    useEffect(() => {
        getPatientData();
        fetchPatientImages();
    }, [])

    const getPatientData = async () => {
        try {
            const response = await axios.get(baseURL + '/getPatient_Pillow/' + id)
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                setPatient(response.data.Success[0]);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    const fetchPatientImages = async () => {
        try {
            const response = await axios.get(baseURL + `/getPatientImages_Pillow/` + id)
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                const images = response.data.Success;
                setImages(images);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    const getRelatedAgentId = () => {
        if (!(patient as unknown as Patient_Pillow).related_agent) {
            return "0";
        } else {
            return (patient as unknown as Patient_Pillow).related_agent;
        }
    }

    const handleDeleteImageOnClick = (id: any) => {
        openDialog(id);
    }

    const handleDeleteImage = async () => {
        try {
            // Send a DELETE request to the server to delete the image
            const response = await axios.delete(baseURL + `/deletePatientImage/` + currentImageId);
            // Check if the deletion was successful
            if (response.data.Success) {
                // Image deletion successful, perform any additional actions (e.g., update UI)
                window.location.reload();
                showSuccessToast(response.data.Success);

            } else {
                // Image deletion failed, handle error
                showErrorToast(response.data.Error)
            }
        } catch (error) {
            // An error occurred while deleting the image
            console.error('Error deleting image:', error);
            showErrorToast('Error deleting image: ' + error);
        }
    };

    const navigate = useNavigate();

    return (
        <div>
            {((currentUser as unknown as User).is_admin == "1" || ((currentUser as unknown as User).id == getRelatedAgentId()) ?
                <div>
                    <div>
                        <div className="flex justify-start mb-5">
                            <NavLink end to={'..'} onClick={(e) => {
                                e.preventDefault();
                                navigate('/tchPillowPatientManagement');
                            }} className="flex items-center">
                                <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-500 dark:fill-gray-400" aria-hidden="true" />
                                <p className="text-sm text-gray-500 dark:text-gray-400 sm:truncate sm:tracking-tight">Back to Patient Management</p>
                            </NavLink>


                        </div>
                        <div className="lg:flex lg:items-center lg:justify-between mb-5">
                            <div className="min-w-0 flex-1">
                                <h2 className="text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:tracking-tight">
                                    {(patient as unknown as Patient_Pillow).fullname}
                                </h2>
                            </div>
                            <div className="mt-5 flex lg:ml-4 lg:mt-0">

                                <span className="sm:ml-3">
                                    <NavLink end to={`/editTchPillowPatient/${id}`}>
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                            Edit
                                        </button></NavLink>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white dark:bg-[#182235] px-4 py-5 sm:px-6 rounded-md'>

                        <div className="px-4 sm:px-0">
                            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Patient Information</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Personal details and application.</p>
                        </div>
                        <div className="mt-6 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Fullname</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).fullname}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Date of Birth</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{dateFormat((patient as unknown as Patient_Pillow).dob, "yyyy-mm-dd")}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Email Address</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).email || "-"}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Mobile Phone No (cm)</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).mobile_no || "-"}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Height (cm)</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).height || "-"} cm</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Weight (cm)</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).weight || "-"} kg</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Gender</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).gender}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Street Address</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).street_address}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"> 矢状缝-枕外嵴 Sagittal Suture - External Occipital Protuberance (cm)</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).sagittal_suture} cm</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">枕外嵴-胸椎 External Occipital Protuberance - Thoracic Vertebrae (cm)</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).external_occipital_protuberance} cm</dd>
                                </div> <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">两侧肩膀对位 Aligning of both shoulders (cm)</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).both_shoulders_align} cm</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Remarks</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).remarks || ""}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Purchase Quantity</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).purchasequantity}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Agent Assigned</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).agent_fullname}</dd>
                                </div>


                            </dl>
                        </div>
                        <div className="px-4 py-8 sm:px-0">
                            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Order Information</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Personal details and application.</p>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Status</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).status || "-"}</dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Method</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).method || "-"}</dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Tracking No</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).tracking_no || "-"}</dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Order Remark</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-gray-300">{(patient as unknown as Patient_Pillow).order_remarks || "-"}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className='py-8 border-b border-gray-900/10 dark:border-gray-50/10 pb-12'>
                            <div className="px-4 sm:px-0">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Patient Images</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Patient Images for Reference</p>
                            </div>
                            {(images.length > 0) ? images.map((image: any, i) => (
                                <div key={i} className='mt-5'>
                                    {/* <p className="mt-5 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                                Image {i + 1}
                                            </p> */}
                                    <div className="flex flex-1 items-end justify-between text-sm border-t border-gray-900/10 py-8">
                                        <p className="text-gray-500">Image {i + 1}</p>

                                        <div className="flex">
                                            <button
                                                type="button"
                                                className="font-medium text-red-600 hover:text-indigo-500"
                                                onClick={() => handleDeleteImageOnClick(image.id)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                    {/* <img className="w-full max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                                src={image.image_data} alt={image.image_data} /> */}
                                    <img className="w-full max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4" key={i} src={`data:image/jpeg;base64,${image.image_data}`} alt={`Image ${i + 1}`} />
                                </div>
                            )) : <p className="mt-5 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                No Patient Images found.
                            </p>}
                        </div>
                    </div>
                </div> : <div className='bg-white dark:bg-[#182235] px-4 py-5 sm:px-6 rounded-md h-96 content-center'><p className="text-sm leading-6 text-gray-600 dark:text-gray-400 text-center">
                    You have no permission to view this user.
                </p></div>)

            }
            <AlertDialog open={dialogIsOpen} onClose={closeDialog} title={"Delete Patient Image"} message={`Do you want to delete this image from database?`} handleDeleteFunction={handleDeleteImage} />

        </div>

    );
}

export default ViewTchPillowPatient;