import { useEffect, useState } from "react";
import SaveBreadcrumb from "../../Components/Layout/Breadcrumb/SaveBreadcrumb";
import DefaultLayout from "../Layout/DefaultLayout";
import axios from "axios";
import ValidateUserForm from "../../Util/ValidateUserForm";
import { NavLink, useNavigate } from "react-router-dom";
import User from "../../Model/User";
import { useCurrentUser } from "../../Providers/UserProvider";
import showErrorToast from "../../Util/Widgets/Toasts/ShowErrorToast";
import showSuccessToast from "../../Util/Widgets/Toasts/ShowSuccessToast";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import showLoadingToast from "../../Util/Widgets/Toasts/ShowLoadingToast";
import toast from "react-hot-toast";

const AddUserCopy = () => {

    useEffect(() => {
        document.title = "Create New User"
    }, []);

    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;
    const [values, setValues] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        agent_no: '',
        fullname: '',
        role_id: "1",
        email: '',
        mobile_no: '',
        country: 'Malaysia',
        street_address: '',
        related_agent: "1",

    })

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        // Remove leading and trailing spaces
        const trimmedValue = value.trim();

        // Convert value to lowercase and remove spaces
        const sanitizedValue = trimmedValue.toLowerCase().replace(/\s/g, '');

        // Remove special characters using regex
        const sanitizedValueWithoutSpecialChars = sanitizedValue.replace(/[^\w]/g, '');

        // Update the state with the sanitized value
        setValues(prevValues => ({
            ...prevValues,
            username: sanitizedValueWithoutSpecialChars
        }));
    };

    //Submit Form
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        //Validation Form
        const username = values['username'];
        try {
            showLoadingToast("Creating User...");
            const response = await axios.post(baseURL + '/checkUsername', { username });
            if (response.data.Error) {
                showErrorToast(response.data.Error);
            }
            if (response.data.Success) {
                try {
                    const addUserResponse = await axios.post(baseURL + '/addUser', values);
                    if (addUserResponse.data.Success) {
                        setTimeout(() => {
                            showSuccessToast(addUserResponse.data.Success);
                            navigate('/usermanagement');
                        }, 2000)

                    } else {
                        showErrorToast(addUserResponse.data.Error);
                    }

                } catch (error) {
                    console.error('Error:', error);
                    showErrorToast('Error: ' + error);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error: ' + error);
        }
    }

    const adminOptions = [{
        name: 'Administrator',
        value: '1',
    }, {
        name: 'TCH Pillow Agent Level 1',
        value: '2',
    }, {
        name: 'TCH Pillow Agent Level 2',
        value: '3',
    }]

    const agentOptions = [{
        name: 'TCH Pillow Agent Level 2',
        value: '3',
    }]

    return (
        <div>
            <SaveBreadcrumb pageName="Create a New User" saveFunction={handleSubmit} />
            <div className='bg-white dark:bg-[#182235] px-3 py-5 sm:px-6 rounded-md'>
                <form onSubmit={handleSubmit}>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">User Account</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                Please provide necessary information to create user account.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Username
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                maxLength={20}
                                                type="text"
                                                name="username"
                                                id="username"
                                                autoComplete="username"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 items-center"
                                                onChange={handleUsernameChange} value={values.username}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Password
                                    </label>
                                    <div className="mt-2 relative rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            maxLength={50}
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            autoComplete="password"
                                            className="block w-full border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            onChange={handleChange}
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            {passwordVisible ? (
                                                <EyeSlashIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            ) : (
                                                <EyeIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Password
                                    </label>
                                    <div className="mt-2 relative rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            autoComplete="confirmPassword"
                                            className="block w-full border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            onChange={handleChange}
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            {passwordVisible ? (
                                                <EyeSlashIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            ) : (
                                                <EyeIcon className="h-4 w-4 text-gray-400 dark:text-gray-300 cursor-pointer" onClick={togglePasswordVisibility} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Personal Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">Use a permanent address where you can receive mail.</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                                        Fullname (As Identity Card)
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                maxLength={50}
                                                type="text"
                                                name="fullname"
                                                id="fullname"
                                                autoComplete="fullname"
                                                className="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="mobile_no" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Mobile Phone Number
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={15}
                                            id="mobile_no"
                                            name="mobile_no"
                                            type="number"
                                            autoComplete="mobile_no"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Country
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        >
                                            <option className='px-2 py-2' value={"Malaysia"}>Malaysia</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street_address" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Street address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            maxLength={90}
                                            type="text"
                                            name="street_address"
                                            id="street_address"
                                            autoComplete="street_address"
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                        <NavLink end to={'..'} onClick={(e) => {
                            e.preventDefault();
                            navigate('/usermanagement');
                        }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                Cancel
                            </button></NavLink>

                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>

        </div>
    )

}

export default AddUserCopy;