import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Tree, TreeNode } from 'react-organizational-chart';
import { NavLink, useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import LoadingWidget from '../../Util/Widgets/LoadingWidget';
import showLoadingToast from '../../Util/Widgets/Toasts/ShowLoadingToast';
import showSuccessToast from '../../Util/Widgets/Toasts/ShowSuccessToast';

interface HierarchyUser {
    id: number;
    fullname: string;
    username: string;
    password: string;
    agent_no: string;
    related_agent: number | null;
    level?: number;
    children?: HierarchyUser[];
}

const UserHierarchyTree: React.FC = () => {
    const navigate = useNavigate();
    const [hierarchy, setHierarchy] = useState<HierarchyUser[]>([]);
    const baseURL = process.env.REACT_APP_BASEURL;
    const treeRef = useRef<HTMLDivElement>(null);
    const hiddenTreeRef = useRef<HTMLDivElement>(null); // Hidden container for capturing the tree
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchHierarchyData();
    }, []);

    const fetchHierarchyData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(baseURL + '/getAllUserHierarchy');
            if (response.data.Success) {
                setHierarchy(response.data.Success);
            } else {
                console.error('Error fetching data:', response.data.Error);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    const renderTreeNodes = (nodes: HierarchyUser[]) => {
        return nodes.map((node) => (
            <TreeNode key={node.id} label={
                <div className='bg-indigo-50 rounded-lg p-6 justify-center items-center flex max-w-full'>
                    <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto justify-start items-start">
                            <p className="text-sm font-semibold leading-6 text-gray-900 uppercase">{node.fullname} </p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{node.agent_no || "No Agent No"}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">Level {node.level}</p>
                        </div>
                    </div>
                </div>
            }>
                {node.children && renderTreeNodes(node.children)}
            </TreeNode>
        ));
    };

    const createHierarchyTree = (data: HierarchyUser[]) => {
        const map: { [key: number]: HierarchyUser } = {};
        const roots: HierarchyUser[] = [];

        data.forEach(item => {
            item.children = [];
            map[item.id] = item;

            if (item.related_agent === null) {
                roots.push(item);
            } else {
                if (map[item.related_agent]) {
                    map[item.related_agent].children!.push(item);
                }
            }
        });

        return roots;
    };

    const exportAsPDF = () => {
        if (treeRef.current) {
            showLoadingToast('Saving as PDF...')
            // Clone the tree into the hidden container
            if (hiddenTreeRef.current) {
                hiddenTreeRef.current.innerHTML = treeRef.current.innerHTML;

                // Calculate the total height and width of the hidden tree
                const hiddenTreeHeight = hiddenTreeRef.current.scrollHeight;
                const hiddenTreeWidth = hiddenTreeRef.current.scrollWidth;

                // Set the height and width of the hidden container to fit the entire tree
                hiddenTreeRef.current.style.height = `${hiddenTreeHeight}px`;
                hiddenTreeRef.current.style.width = `${hiddenTreeWidth}px`;

                // Use html2canvas to capture the entire hidden tree
                html2canvas(hiddenTreeRef.current).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF({
                        orientation: 'landscape',
                        unit: 'pt',
                        format: 'a4'
                    });

                    // Calculate the scale factor to fit the image within the PDF dimensions
                    const scaleFactor = Math.min(pdf.internal.pageSize.getWidth() / hiddenTreeWidth, pdf.internal.pageSize.getHeight() / hiddenTreeHeight);

                    setTimeout(() => {
                        // Add the scaled image to the PDF
                        pdf.addImage(imgData, 'PNG', 0, 0, hiddenTreeWidth * scaleFactor, hiddenTreeHeight * scaleFactor);
                        pdf.save('tch_user_hierarchy_tree.pdf');
                        showSuccessToast('Saved as tch_user_hierarchy_tree.pdf')
                    }, 1200)
                });
            }
        }
    };

    const hierarchyTree = createHierarchyTree(hierarchy);

    return (
        <div>
            <div className="flex justify-start mb-5">
                <NavLink end to={'..'} onClick={(e) => {
                    e.preventDefault();
                    navigate('/usermanagement');
                }} className="flex items-center">
                    <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-500 dark:fill-gray-400" aria-hidden="true" />
                    <p className="text-sm text-gray-500 dark:text-gray-400 sm:truncate sm:tracking-tight">Back to User Management</p>
                </NavLink>
            </div>
            <div>
                <div className="lg:flex lg:items-center lg:justify-between mb-5">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
                            User Hierarchy Tree
                        </h2>
                        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                Visualizes a hierarchical structure of users and their related agents using a tree diagram
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="hidden sm:block">
                            <button
                                type="button"
                                onClick={exportAsPDF}
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Export as PDF
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-[#182235] px-4 py-3 sm:px-6 sm:py-6 rounded-md overflow-x-auto" ref={treeRef}>
                {loading ? (
                    <div className='py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center h-screen'>
                        <LoadingWidget />
                        <p className="mt-4 text-sm font-medium leading-6 text-gray-500 dark:text-white text-center">{`Loading User Data...`}</p>
                    </div>
                ) : (
                    <Tree
                        lineWidth={'5px'}
                        lineColor={'gray'}
                        lineBorderRadius={'10px'}
                        label={<div className='bg-indigo-300 rounded-lg p-4 font-semibold'>User Hierarchy Tree</div>}
                    >
                        {renderTreeNodes(hierarchyTree)}
                    </Tree>
                )}
            </div>
            {/* Hidden container for capturing the tree */}
            <div ref={hiddenTreeRef} style={{ position: 'absolute', top: -9999, left: -9999 }} className='p-6'></div>
        </div>
    );
}

export default UserHierarchyTree;
