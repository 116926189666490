import { useEffect, useState } from 'react';
import DefaultLayout from '../Layout/DefaultLayout';
import Patient_Pillow from '../../Model/Patient_Pillow';
import SaveBreadcrumb from '../../Components/Layout/Breadcrumb/SaveBreadcrumb';
import { NavLink, useNavigate } from 'react-router-dom';
import { PhotoIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { useCurrentUser } from '../../Providers/UserProvider';
import User from '../../Model/User';
import showErrorToast from '../../Util/Widgets/Toasts/ShowErrorToast';
import showSuccessToast from '../../Util/Widgets/Toasts/ShowSuccessToast';


const AddTchPillowPatientImages = () => {
    const navigate = useNavigate();

    const [patients, setPatients] = useState<Patient_Pillow[]>([]);
    const [selectedPatientId, setSelectedPatientId] = useState<any>();
    const [files, setFiles] = useState([] as any);
    const [imagesURLs, setImageURLs] = useState([]);
    const [fileName, setFileName] = useState<any>();
    const baseURL = process.env.REACT_APP_BASEURL;

    const { currentUser } = useCurrentUser();

    useEffect(() => {
        getPatient((currentUser as unknown as User).id);
    }, [])

    const getPatient = async (id: any) => {
        try {
            let getAllPatientURL = ((currentUser as unknown as User).is_admin == "1") ? (baseURL + '/getAllPatient_Pillow/')
                : (baseURL + `/getAllPatient_PillowByOwnRelatedAgentId/${id}}`)

            const response = await axios.get(getAllPatientURL)
            if (response.data.Success) {
                if (response.data.Success.length === 0) {
                    setPatients([]);
                    setSelectedPatientId('undefined')
                } else {
                    setPatients(response.data.Success || []);
                    setSelectedPatientId(response.data.Success[0].id)
                }

            } else {
                showErrorToast(response.data.Error);
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error:' + error);
        }
    }

    useEffect(() => {
        document.title = "Add TCH Pillow Patient Images"
    }, []);

    //Submit Form
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("patient_id", selectedPatientId);
        if (files != null) {
            for (let i = 0; i < files.length; i++) {
                formData.append(`image_data`, files[i]);
            }
        }

        // // Uncomment for debugging
        // // Display the key/value pairs
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }

        if (selectedPatientId == "undefined") {
            showErrorToast("Please select a patient.")
        } else {
            try {
                const response = await axios.post(baseURL + '/uploadPatientImages_Pillow', formData);
                if (response.data.Error) {
                    showErrorToast(response.data.Error);
                }
                if (response.data.Success) {
                    window.location.reload();
                    showSuccessToast(response.data.Success);
                }
                // Optionally, you can perform additional actions upon successful form submission
            } catch (error) {
                console.error('Error:', error);
                showErrorToast('Error:' + error);
                // Optionally, you can display an error message to the user
            }
        }
    };

    //file
    const handleFile = (e: any) => {

        if (e.target.files.length > 0) {
            if (checkFileSize(e.target.files)) {
                const renamedFiles = Array.from(e.target.files).map((file: any) => {
                    // Create a new File object with the updated name
                    const newName = `${(currentUser as unknown as User).fullname}_${(currentUser as unknown as User).id}_${Date.now()}_${file.name}`;
                    const trimNewName = newName.trim()
                    return new File([file], trimNewName, { type: file.type });
                });
                setFiles(renamedFiles);

                let currentFileName = e.target.files[0].name;
                for (let i = 1; i < e.target.files.length; i++) {
                    currentFileName = currentFileName + ", " + e.target.files[i].name;
                }
                setFileName(currentFileName);
            }
        } else {
            setFiles(null);
            setFileName(null);
        }
    }

    const checkFileSize = (checkFile: any) => {
        let foundNoExceed = true;
        for (let i = 0; i < checkFile.length; i++) {
            const file = checkFile[i];
            if (file && file.size > 15000000) {
                showErrorToast("File size exceeds the limit of 10MB. Please try again.");
                foundNoExceed = false;
            } else {
                foundNoExceed = true;
            }
        }
        return foundNoExceed;
    }

    useEffect(() => {
        if (files.length < 1) return;
        const newImageUrls: any = [];
        files.forEach((image: any) => newImageUrls.push(URL.createObjectURL(image)));
        setImageURLs(newImageUrls);
    }, [files]);

    return (
        <div>

            <SaveBreadcrumb pageName="Add Patient Images" saveFunction={handleSubmit} />
            <div className='bg-white dark:bg-[#182235] px-3 py-5 sm:px-6 rounded-md'>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 dark:border-gray-50/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Pillow Patient Images</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                Please select patient and upload their corresponding images.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="patient_id" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        Patient
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="patient_id"
                                            name="patient_id"
                                            autoComplete="patient_id"
                                            className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                            onChange={(e) => setSelectedPatientId(e.target.value)}
                                        >
                                            {patients.map((patient, i) => (
                                                <option key={i} className='px-2 py-2' value={patient.id}>{(patient as unknown as Patient_Pillow).fullname}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-span-full">
                                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                        背部与侧面照片 Back and Side Photos

                                    </label>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 dark:border-gray-50/25 px-6 py-10">
                                        <div className="text-center">
                                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer rounded-md bg-white dark:bg-[#182235] font-semibold text-indigo-600 dark:text-indigo-200 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        className="sr-only"
                                                        multiple
                                                        onChange={handleFile}
                                                        accept=".jpg,.jpeg,.png" />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG up to 10MB</p>
                                        </div>
                                    </div>
                                    { }

                                    {files != null ? <p className="text-center mt-4 text-xs leading-5 text-gray-600">{fileName}</p> : <p className="text-center mt-4 text-xs leading-5 text-gray-600">No file selected</p>}

                                </div>

                            </div>

                        </div>
                        <div className='border-b border-gray-900/10 dark:border-gray-50/10 pb-12'>
                            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Preview Images</h2>
                            {(imagesURLs.length > 0) ? imagesURLs.map((imageSrc: any, i) => (
                                <div key={i} className=''>
                                    <p className="mt-5 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                        Image {i + 1}
                                    </p>
                                    <img className="w-full max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                        src={imageSrc} alt={imageSrc} />
                                </div>
                            )) : <p className="mt-5 text-sm leading-6 text-gray-600 dark:text-gray-400">
                                No Patient Images found.
                            </p>}
                        </div>
                    </div>


                    <div className="mt-6 mb-2 flex items-center justify-end gap-x-6">
                        <NavLink end to={'..'} onClick={(e) => {
                            e.preventDefault();
                            navigate('/tchPillowPatientManagement');
                        }}><button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                Cancel
                            </button></NavLink>

                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div >

        </div >

    );
}

export default AddTchPillowPatientImages;