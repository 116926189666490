
import { useEffect, useState } from 'react';
import DefaultLayout from './Layout/DefaultLayout';
import { useCurrentUser } from '../Providers/UserProvider';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Sidebar from '../Components/Layout/Sidebar';
import Topbar from '../Components/Layout/Topbar';
import io from 'socket.io-client';
import showErrorToast from '../Util/Widgets/Toasts/ShowErrorToast';
import User from '../Model/User';
import axios from 'axios';
import NotificationWidget from '../Util/Widgets/NotificationWidget';
import LoadingWidget from '../Util/Widgets/LoadingWidget';

const Dashboard = () => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    document.title = "Dashboard"
  }, []);

  const [users, setUser] = useState<any[]>([]);
  const [patients, setPatient] = useState<any[]>([]);
  const [totalPillowPurchaseQuantity, setTotalPillowPurchaseQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    getUser(currentUser.id);
    getPatient(currentUser.id);

    if (patients.length > 0) {
      let total = 0;
      patients.forEach((pat: any) => {
        total = total + parseInt(pat.purchasequantity, 10);
        setTotalPillowPurchaseQuantity(total);
      })
    }
  }, [])

  const getUser = async (id: any) => {
    try {
      setLoading(true);
      let getAllUserURL = ((currentUser as unknown as User).is_admin == "1") ? (baseURL + '/getAllUser/')
        : (baseURL + `/getAllUserByOwnRelatedAgentId/${id}}`)

      const response = await axios.get(getAllUserURL)
      if (response.data.Success) {
        const filteredUserList: any = [];
        response.data.Success.forEach((user: any) => {
          if ((user as unknown as User).id !== (currentUser as unknown as User).id) {
            filteredUserList.push(user);
          }
        });
        setUser(filteredUserList);
      } else {
        showErrorToast(response.data.Error);
      }
    } catch (error) {
      console.error('Error: ', error);
      showErrorToast('Error: ' + error);
    } finally {
      setLoading(false);
    }
  }

  const getPatient = async (id: any) => {
    try {
      setLoading(true);
      let getAllPatientURL = ((currentUser as unknown as User).is_admin == "1") ? (baseURL + '/getAllPatient_Pillow/')
        : (baseURL + `/getAllPatient_PillowByOwnRelatedAgentId/${id}}`)

      const response = await axios.get(getAllPatientURL)
      if (response.data.Success) {
        setPatient(response.data.Success);
      } else {
        showErrorToast(response.data.Error);
      }
    } catch (error) {
      console.error('Error:', error);
      showErrorToast('Error:' + error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <main>
      {(loading) ? <div className='py-16 text-sm font-medium leading-6 text-gray-900 dark:text-white text-center'>
        <LoadingWidget />
        <p className="mt-4 text-sm font-medium leading-6 text-gray-500 dark:text-white text-center">{`Loading Data...`}</p>
      </div> : <div>
        <div className="mx-auto max-w-screen-2xl bg-slate-100 dark:bg-slate-900 mb-8">
          <div className="flex flex-col gap-10">
            <div className="relative bg-indigo-200 dark:bg-indigo-500 p-4 sm:p-6 rounded-md overflow-hidden">
              {/* Background illustration */}
              <div className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block" aria-hidden="true">
                <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
                    <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
                    <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
                      <stop stopColor="#A5B4FC" offset="0%" />
                      <stop stopColor="#818CF8" offset="100%" />
                    </linearGradient>
                    <linearGradient x1="50%" y1="24.537%" x2="50%" y2="100%" id="welcome-c">
                      <stop stopColor="#4338CA" offset="0%" />
                      <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
                    </linearGradient>
                  </defs>
                  <g fill="none" fillRule="evenodd">
                    <g transform="rotate(64 36.592 105.604)">
                      <mask id="welcome-d" fill="#fff">
                        <use xlinkHref="#welcome-a" />
                      </mask>
                      <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
                      <path fill="url(#welcome-c)" mask="url(#welcome-d)" d="M64-24h80v152H64z" />
                    </g>
                    <g transform="rotate(-51 91.324 -105.372)">
                      <mask id="welcome-f" fill="#fff">
                        <use xlinkHref="#welcome-e" />
                      </mask>
                      <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
                      <path fill="url(#welcome-c)" mask="url(#welcome-f)" d="M40.333-15.147h50v95h-50z" />
                    </g>
                    <g transform="rotate(44 61.546 392.623)">
                      <mask id="welcome-h" fill="#fff">
                        <use xlinkHref="#welcome-g" />
                      </mask>
                      <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
                      <path fill="url(#welcome-c)" mask="url(#welcome-h)" d="M40.333-15.147h50v95h-50z" />
                    </g>
                  </g>
                </svg>
              </div>

              {/* Content */}
              <div className="relative">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">Welcome Back! {currentUser.fullname} 👋</h1>
                <p className="dark:text-indigo-200 text-sm mt-2">Perfection is not attainable, but if we chase perfection we can catch excellence.</p>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-gray-100 dark:bg-gray-800 rounded-lg">
          <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4 mt-2">Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-white dark:bg-gray-700 p-6 rounded-md shadow">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">Total Users</p>
              <h3 className="text-2xl font-bold text-slate-800 dark:text-indigo-200">{users.length}</h3>
            </div>
            <div className="bg-white dark:bg-gray-700 p-6 rounded-md shadow">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">Total Patients</p>
              <h3 className="text-2xl font-bold text-slate-800 dark:text-indigo-200">{patients.length}</h3>
            </div>

          </div>
        </div>
      </div>}
    </main>


  );
}

export default Dashboard;