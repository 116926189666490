import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/20/solid';
import { NavLink, useNavigate } from 'react-router-dom';

interface BreadcrumbProps {
    pageName: string;
    saveFunction: Function;
}

const SaveBreadcrumb = ({ pageName, saveFunction }: BreadcrumbProps) => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="flex justify-start mb-5">
                <NavLink end to={'..'} onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }} className="flex items-center">
                    <ArrowLeftIcon className="h-4 w-4 mr-1 fill-gray-500 dark:fill-gray-400" aria-hidden="true" />
                    <p className="text-sm text-gray-500 dark:text-gray-400 sm:truncate sm:tracking-tight">Back</p>
                </NavLink>


            </div>
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <div className="min-w-0 flex-1">
                    <h2 className="text-xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:tracking-tight">
                        {pageName}
                    </h2>
                </div>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">


                    <span className="ml-0">
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-indigo-600 dark:bg-indigo-300 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={(e) => saveFunction(e)}
                        >
                            <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            Save
                        </button>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SaveBreadcrumb;
